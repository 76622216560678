import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import { useMsal, useAccount } from "@azure/msal-react";
import PostTarget from '../../API/PostTarget';
import { fetchPalisades } from '../../reducer/palisades';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { inventoryTenantId } from '../Utilities/api';

const AddTargetDialog = (props) => {
    const dispatch = useDispatch();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    // Targets should not be added to inventory palisades
    const palisadesList = useSelector(state => state.palisades).filter(p => p.tenantId != inventoryTenantId);

    const [target, setTarget] = React.useState('');
    const [palisade, setPalisade] = React.useState('');
    const [description, setDescription] = React.useState('');

    React.useEffect(() => {
        if (account) {
            dispatch(fetchPalisades(instance, account));
        }
    }, [account, instance, dispatch]);

    React.useEffect(() => {
        setPalisade(palisadesList[0]);
    }, [palisadesList]);

    const handleTargetChange = (event) => {
        setTarget(event.target.value)
    }

    const handlePalisadeChange = (event) => {
        setPalisade(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value)
    }

    const handleClose = () => {
        props.handleClose();
        setTarget('');
        setPalisade(palisadesList[0]);
        setDescription('');
    }

    const addTarget = async () => {
        PostTarget(
            instance,
            account,

            { TargetId: target, PalisadeId: palisade.id, TenantId: palisade.tenantId, Description: description }
        ).then(response => {
            if (response.ok) {
                response.json().then((newTarget => {
                    props.addRow(newTarget);
                    toast.success("Successfully added target");
                }))
            } else {
                toast.error("Unable to add target");
            }
        }, (error) => {
            toast.error("Unable to add target");
        })
        handleClose();
    }

    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth>
            <DialogTitle>ADD TARGET MAPPING</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} width={1000}>
                    <Grid item xs={3}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="target-id"
                            label="Target ID"
                            helperText="Protected System Id"
                            fullWidth
                            variant="outlined"
                            onChange={handleTargetChange}
                            required
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            autoFocus
                            select
                            margin="dense"
                            id="palisade-id"
                            label="Sensor Id"
                            helperText="Palisade Sensor Id"
                            fullWidth
                            variant="outlined"
                            defaultValue={palisade}
                            value={palisade}
                            onChange={handlePalisadeChange}
                            required
                        >
                            {
                                palisadesList.map(palisade =>
                                    props.protectedPalisades.has(palisade.id) ? <></> :
                                        <MenuItem value={palisade}>{palisade.id}</MenuItem>
                                )
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="description-type"
                            label="Description"
                            fullWidth
                            variant="outlined"
                            onChange={handleDescriptionChange}
                            required
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleClose()}
                    variant="contained"
                    color="secondary"
                >
                    Cancel
                </Button>
                <Button
                    onClick={addTarget}
                    disabled={!(target && palisade)}
                    variant="contained"
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddTargetDialog