import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GetSettings from "../../API/GetSettings";
import UpdateSettings from "../../API/UpdateSettings";
import './NotificationEmail.css';

const NotificationEmail = (props) => {
  const { loginInfo } = props;
  const { instance, account } = loginInfo;
  const [email, setEmail] = React.useState("");
  const [id, setId] = React.useState("");
  const [storedEmail, setStoredEmail] = React.useState("");
  const [error, setErrorStatus] = React.useState(false);
  const [alertBuffer, setAlertBuffer] = React.useState("");
  const [storedAlertBuffer, setStoredAlertBuffer] = React.useState("");
  const [bufferError, setBufferError] = React.useState(false);
  var role = "";
  if (account) {
    role = account.idTokenClaims?.extension_appRole?.toLowerCase();
  }


  React.useEffect(() => {
    async function getData() {
      if (account) {
        await GetSettings(instance, account).then((response) => {
          setEmail(response.notificationEmail);
          setStoredEmail(response.notificationEmail);
          setId(response.id);
          setAlertBuffer(response.notificationsAlertBuffer.toString());
          setStoredAlertBuffer(response.notificationsAlertBuffer.toString());
        });
      }
    }
    getData();
  }, [account, instance]);


  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleAlertChange = (event) => {
    setAlertBuffer(event.target.value);
  };

  const checkEmailValidations = () => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      setErrorStatus(false);
      return true;
    } else {
      setErrorStatus(true);
      return false;
    }
  };



  const checkAlertBuffer = () => {
    const int32Max = 2147483647;
    if (alertBuffer.length > 10 || !(/^[0-9]+$/.test(alertBuffer))
      || parseInt(alertBuffer) < 0 || parseInt(alertBuffer) > int32Max) {
      setBufferError(true);
      return false;
    } else {
      setBufferError(false);
      return true;
    }
  };


  const saveNotificationEmail = () => {
    const isEmailAdressValid = checkEmailValidations();
    const isAlertBufferValid = checkAlertBuffer();
    if (isEmailAdressValid && isAlertBufferValid) {
      UpdateSettings(instance, account, id, email, parseInt(alertBuffer)).then((response) => {
        if (response.ok) {
          setStoredEmail(email);
          setStoredAlertBuffer(alertBuffer);
          toast.success("Notification Settings Saved Succesfully!");
        } else {
          toast.error("Error Saving Notification Settings!");
        }
      });
    }
  };

  return (
    <div className='notification-email-container'>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          "& button": { m: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        <div style={{ marginLeft: '15px', fontWeight: '600' }}>
          <label> Notifications Email: </label>
        </div>
        <div style={{ margin: "8px" }}>
          {!error && (
            <TextField
              required
              style={{ width: "100%" }}
              id="notification-email-input"
              value={email}
              onChange={handleChange}
              disabled={!(role === "admin" || role === "superadmin")}
            />
          )}
          {error && (
            <TextField
              required
              style={{ width: "100%" }}
              id="notification-email-input"
              value={email}
              onChange={handleChange}
              helperText="Incorrect Email Format"
              disabled={!(role === "admin" || role === "superadmin")}
              error
            />
          )
          }
        </div>
        <div style={{ marginLeft: '15px', fontWeight: '600' }}>
          <label> Notifications Alert Buffer (minutes): </label>
        </div>
        <div style={{ margin: "8px" }}>
          {!bufferError && (
            <TextField
              required
              style={{ width: "100%" }}
              id="notification-buffer-input"
              value={alertBuffer}
              helperText="A silent interval for alerts of the same type and the same protected target system"
              onChange={handleAlertChange}
              disabled={!(role === "admin" || role === "superadmin")}
            />
          )}
          {bufferError && (
            <TextField
              required
              style={{ width: "100%" }}
              id="notification-buffer-input"
              value={alertBuffer}
              helperText="Notifications Alert Buffer must be a positive integer in range"
              onChange={handleAlertChange}
              disabled={!(role === "admin" || role === "superadmin")}
              error
            />
          )}
        </div>

        <div>
          <Button
            variant="outlined"
            size="medium"
            style={{ margin: "15px" }}
            onClick={() => {
              setEmail(storedEmail);
              setErrorStatus(false);
              setBufferError(false);
              setAlertBuffer(storedAlertBuffer);
            }}
            disabled={!(role === "admin" || role === "superadmin")}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            style={{ margin: "15px" }}
            onClick={(event) => {
              saveNotificationEmail(event);
            }}
            disabled={!(role === "admin" || role === "superadmin")}
          >
            Save
          </Button>
        </div>
      </Box>
      <ToastContainer />
    </div>
  );
};

export default NotificationEmail;
