import {
    Grid,
    Switch,
    Box,
    TextField
} from "@mui/material";

import { milliSecondsToTime, timeToMilliSeconds } from "../Utilities/dates";

const BootUpRule = (props) => {
    const rule = props.rule;
    const lowerBound = JSON.parse(rule.parameters)["lower-bound"];


    const handleTimeChange = (event, upperBound) => {
        var newRule = props.rule;
        var parameters = JSON.parse(props.rule.parameters);
        const boundType = upperBound ? "upper-bound" : "lower-bound";
        parameters[boundType] = timeToMilliSeconds(event.target.value);
        newRule.parameters = JSON.stringify(parameters);
        props.addChangedRule(newRule);
    }

    return (
        <Grid container>
            <Grid item xs={11} sx={{ display: `flex`, alignItems: "center", justifyContent: "flex-start" }}>
                <Box sx={{ display: `flex`, alignItems: "center", fontSize: 20, padding: 2 }}>
                    <b>When&nbsp;</b> a system bootup is detected between&nbsp;
                    <Grid item xs="auto">
                        <TextField
                            disabled={!props.editsAuthorized}
                            value={milliSecondsToTime(JSON.parse(props.rule.parameters)["lower-bound"])}
                            label="Time of Day"
                            size="small"
                            type="time"
                            onChange={event => handleTimeChange(event, false)}
                        />
                        &nbsp;and&nbsp;
                        <TextField
                            disabled={!props.editsAuthorized}
                            value={milliSecondsToTime(JSON.parse(props.rule.parameters)["upper-bound"])}
                            label="Time of Day"
                            size="small"
                            type="time"
                            onChange={event => handleTimeChange(event, true)}
                        />
                    </Grid>
                    ,<b>&nbsp;Then&nbsp;</b> send an alert
                </Box>
            </Grid>
            <Grid item xs={1} sx={{ display: `flex`, alignItems: "center", justifyContent: "flex-end" }}>
                <Switch
                    checked={props.rule.enabled}
                    color={"success"}
                    disabled={!props.editsAuthorized}
                    onChange={(event) => { props.handleEnabledChange(props.rule) }} />
            </Grid>
        </Grid>
    )
};

export default BootUpRule;