import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";

import { authenticationParameters, signInConfig } from "./authConfig";
import reportWebVitals from './reportWebVitals';
import App from './App';
import Invitation from './Components/Pages/Invitation';
import store from "./reduxStore";
import './index.css';


/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders. 
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
 const msalInstance = new PublicClientApplication(signInConfig);

 function ErrorComponent({error}) {
  return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}
ReactDOM.render(
    <MsalProvider instance={msalInstance}>
      <Provider  store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/redeem-invite/:jwt" element={<Invitation/>}/>
            <Route path="*" element={
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect} 
                authenticationRequest={authenticationParameters} 
                errorComponent={ErrorComponent} 
                loadingComponent={LoadingComponent}
              >  
                <App/>
              </MsalAuthenticationTemplate>
            }/>
          </Routes>
        </BrowserRouter>
      </Provider>
    </MsalProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
