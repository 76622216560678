import { createSlice } from "@reduxjs/toolkit";

export const energySlice = createSlice({
  name: "energy",
  initialState: {
    energyData: null,
    energyChangeOverPastWeek: 0,
    energyUsageChartData: {
      labels: [],
      datasets: [
        {
          data: [],
          label: false,
        },
      ],
    },
  },
  reducers: {
    setEnergyData: (state, action) => {
      return { ...state, energyData: action.payload };
    },
    setEnergyChangeOverPastWeek: (state, action) => {
      return { ...state, energyChangeOverPastWeek: action.payload };
    },
    setEnergyUsageChartData: (state, action) => {
      return { ...state, energyUsageChartData: action.payload };
    },
  },
});

export const {
  setEnergyData,
  setEnergyChangeOverPastWeek,
  setEnergyUsageChartData,
} = energySlice.actions;

export const energyReducer = energySlice.reducer;
