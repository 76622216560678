import AcquireToken from "./AcquireToken";
import { NOTIFICATION_SETTING } from './Endpoints';

async function UpdateSettings(instance, account, id, notificationEmail, notificationsAlertBuffer) {
    const accessToken = await AcquireToken(instance, account);
    if (accessToken) {
        return (await fetch(
            `${NOTIFICATION_SETTING}`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Id: id,
                    NotificationEmail: notificationEmail,
                    NotificationsAlertBuffer: notificationsAlertBuffer
                })

            }
        ))
    } else {
        return null;
    }
}

export default UpdateSettings