const tenant = `${process.env.REACT_APP_AUTH_ENDPOINT}`;
const signInPolicy = "B2C_1A_PTV1_SIGNUP_SIGNIN";
const applicationID = `${process.env.REACT_APP_APPLICATION_ID}`;
const reactRedirectUri = "/";
const tenantSubdomain = tenant.split(".")[0];
const instance = `https://${tenantSubdomain}.b2clogin.com/`;
const signInAuthority = `${instance}${tenant}/${signInPolicy}`;
// Msal Configurations
export const signInConfig = {
  auth: {
    authority: signInAuthority,
    clientId: applicationID,
    redirectUri: reactRedirectUri,
    knownAuthorities: [instance]
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true
  }
};

export const authenticationParameters = {
    scopes: [
      `https://${process.env.REACT_APP_AUTH_ENDPOINT}/${process.env.REACT_APP_AZ_ADB2C_BE_CLID}/Devices.Read`
    ]
};