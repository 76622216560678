import { combineReducers } from 'redux';
import { tenantsInfoReducer, tenantsDictReducer } from './tenants';
import { palisadesInfoReducer, palisadesStatsReducer } from './palisades';
import { loginInfoReducer } from './login';
import { aggregatorStatsReducer } from './aggregators';
import { targetsInfoReducer } from './targets';
import { energyReducer } from './energy';
import { rulesAlertReducer, ruleAlertTypesReducer } from './ruleAlert';

const rootReducer = combineReducers({
    tenants: tenantsInfoReducer,
    palisades: palisadesInfoReducer,
    tenantsDict: tenantsDictReducer,
    loginInfo: loginInfoReducer,
    palisadeStats: palisadesStatsReducer,
    aggregatorStats: aggregatorStatsReducer,
    targets: targetsInfoReducer,
    energyUsageData: energyReducer,
    rulesAlertStats: rulesAlertReducer,
    rulesAlertTypes: ruleAlertTypesReducer,
});

export default rootReducer;