import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useMsal, useAccount } from "@azure/msal-react";
import GetTenants from '../../API/GetTenants';
import GetAggregators from '../../API/GetAggregators';
import { isAPIError } from '../Utilities/api';
import { inventoryTenantId } from '../Utilities/api';
import DeploymentStatusDropdown from '../ListItems/DeploymentStatusDropdown';
import { noAggregator } from '../Utilities/api';


const AddPalisadeDialog = (props) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [tenantList, setTenantList] = React.useState([]);

    const [tenant, setTenant] = React.useState(props.tenant || '');
    const [aggregator, setAggregator] = React.useState('');
    const [deploymentStatus, setDeploymentStatus] = React.useState('');

    // maps aggregators to their Tenant Id
    const [aggregatorMap, setAggregatorMap] = React.useState(new Map());
    const handleTenantChange = (event) => {
        setTenant(event.target.value);
        setAggregator(aggregatorMap?.get(event.target.value)?.[0] || '');
    };
    const handleAggregatorChange = (event) => {
        setAggregator(event.target.value);
    }

    const handleDeploymentStatusChange = (event) => {
        setDeploymentStatus(event.target.value);
    }

    React.useEffect(() => {
        async function getData() {
            if (account) {
                let tenants = await GetTenants(instance, account)
                if (isAPIError(tenants)) {
                    tenants = [];
                }
                setTenantList(tenants);
                var tenant = props.tenant || tenants[0]?.id || '';
                setTenant(tenant)

                let aggregators = await GetAggregators(instance, account);
                if (isAPIError(aggregators)) {
                    aggregators = [];
                }
                const newMap = new Map(
                    aggregators.reduce(
                        (mapSoFar, aggregator) => {
                            mapSoFar.set(aggregator.tenantId, (mapSoFar.get(aggregator.tenantId) || []));
                            mapSoFar.get(aggregator.tenantId).push(aggregator.id);
                            return mapSoFar;
                        }, new Map()
                    )
                );
                setAggregatorMap(newMap);
                setAggregator(newMap.get(tenant)?.[0] || '');
            }
        }
        getData();
    }, [account, instance]);

    const handleClose = () => {
        props.handleClose();
        setTenant(props.tenant);
        setAggregator('');
        setDeploymentStatus('');
    }

    React.useEffect(() => {
        setTenant(props.tenant);
        setAggregator(aggregatorMap.get(props.tenant)?.[0] || '');
    }, [aggregatorMap, props.tenant])

    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth>
            <DialogTitle>EDIT PALISADE SENSORS</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={4} sx={{ width: 200 }}>
                        <FormControl required fullWidth sx={{ mt: 1 }}>
                            <InputLabel id="tenant-select-label">Tenant</InputLabel>
                            <Select
                                labelId="tenant-select-label"
                                id="tenant-select"
                                value={tenant}
                                label="Tenant"
                                onChange={handleTenantChange}
                            >
                                {
                                    tenantList.map(({ id, name }) =>
                                        <MenuItem value={id}>{name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sx={{ width: 200 }}>
                        <FormControl required fullWidth sx={{ mt: 1 }}>
                            <InputLabel id="aggregator-select-label">Aggregator</InputLabel>
                            <Select
                                labelId="aggregator-select-label"
                                id="aggregator-select"
                                value={aggregator}
                                label="Aggregator"
                                onChange={handleAggregatorChange}
                            >
                                {
                                    aggregatorMap?.get(tenant)?.map(id =>
                                        <MenuItem value={id}>{id}</MenuItem>
                                    )
                                }
                                {tenant === inventoryTenantId ? <MenuItem value={noAggregator}>{noAggregator}</MenuItem> : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sx={{ width: "400px" }}>
                        <DeploymentStatusDropdown
                            handleChange={handleDeploymentStatusChange}
                            deploymentStatus={deploymentStatus}
                        />
                    </Grid>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center", float: "right" }}>
                        <Button
                            onClick={() => {
                                props.editData(tenant, aggregator, deploymentStatus);
                                handleClose();
                            }}
                            disabled={!tenant}
                            variant="contained"
                            sx={{ height: '80%' }}
                        >
                            CONFIRM
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog >
    );
}

export default AddPalisadeDialog