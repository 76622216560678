import AcquireToken from "./AcquireToken";
import { PALISADE_API } from "./Endpoints";

async function PostPalisadeReset(instance, account, id) {
    const accessToken = await AcquireToken(instance, account);
    if (accessToken) {
        return (await fetch(
            `${PALISADE_API}/${id}/reset`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }

            }
        ))
    } else {
        return null;
    }
}

export default PostPalisadeReset