import React from "react";
import * as msal from "@azure/msal-browser";
import { MsalContext } from "@azure/msal-react";
import {
  AppBar,
  Stack,
  Toolbar,
  Typography,
  IconButton,
  Icon,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { signInConfig } from '../../authConfig';
import "./Navbar.css";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.userName = "";
    this.darkTheme = createTheme({
      palette: {
        mode: "dark",
        primary: {
          main: "#353a42",
        },
      },
    });
  }

  logoutUser = () => {
    this.context = MsalContext;
    const msalInstance = new msal.PublicClientApplication(signInConfig);
    msalInstance.logoutRedirect().then(()=>this.context.instance.loginPopup());
  }

  render() {
    if (this.props.loginInfo) {
      this.userName = JSON.parse(this.props.loginInfo).name;
    }
    return (
      <Stack spacing={2} sx={{ flexGrow: 1 }}>
        <ThemeProvider theme={this.darkTheme}>
          <AppBar position="fixed" className="navbar">
            <div className="row">
              <div className="col-6">
                <img
                  src={process.env.PUBLIC_URL + "/Images/logo-temp.png"}
                  alt="palitronica logo"
                  className="logo-image"
                />
              </div>
              <div className="col-6">
                <Toolbar className="navbar-right-container">
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{ mr: 2 }}
                    onClick={() => this.logoutUser()}
                  >
                    <Icon fontSize="small">logout</Icon>
                  </IconButton>
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ display: { xs: "none", sm: "block" } }}
                  >
                    {this.userName}
                  </Typography>
                </Toolbar>
              </div>
            </div>
          </AppBar>
        </ThemeProvider>
      </Stack>
    );
  }
}
export default NavBar;
