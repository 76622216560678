import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import InventoryTable from './InventoryTable';
import { useMsal, useAccount } from "@azure/msal-react";
import GetUsers from '../../API/GetUsers';
import InviteUserDialog from '../Dialog/InviteUserDialog';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import DeleteUser from '../../API/DeleteUser';

const baseColumns = [
    {
        id: 'name',
        numeric: true,
        disablePadding: true,
        label: 'User Name',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Email Address',
    },
    {
        id: 'tenant',
        numeric: false,
        disablePadding: false,
        label: 'Tenant',
    },
    {
        id: 'role',
        numeric: false,
        disablePadding: false,
        label: 'Role',
    },
];

const baseActions = new Map([
    [
        'delete',
        {
            icon: "delete",
            tooltip: "Delete",
            color: "error",
            onClick: () => { },
        }
    ]
]);

const Users = (props) => {
    const { tenantsDict } = props;
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [ rows, setRows ] = useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [deleteUserId, setDeleteUserId] = React.useState();
    const [userName, setUserName] = React.useState();
    const [userDict, setUserDict] = React.useState();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
    const [actions, setActions] = useState(baseActions);
    
    useEffect(() => {
        async function getData () {
            const role = account.idTokenClaims?.extension_appRole?.toLowerCase();
            if (account) {
                const targets = await GetUsers(instance, account);
                setRows(targets.map(
                    ({name, email, id, appTenantId, appRole}) => ({name, email, id, tenant: tenantsDict[appTenantId], role: appRole})
                ));
                let userDict = {};
                for(let i=0;i<targets.length;i++){
                    userDict[targets[i].id] = targets[i].name;
                  }
                setUserDict(userDict)
            }
            if (role !== "superadmin" && role !== "admin") {
                actions.delete('delete');
                setActions(actions);
            }
        }
        getData();
    }, [account, instance, props.Users]);

    if (actions.get('delete')) {
        actions.get('delete').onClick = (event, userId) => {
            setDeleteUserId(userId);
            setDeleteConfirmOpen(true);
            setUserName(userDict[userId])
        }
    }
    
    const deleteUser = () => {
        DeleteUser(instance, account, deleteUserId)
            .then((response) => {
                if (response.ok) {
                    toast.success("User deleted successfully");
                } else {
                    toast.error("Cannot delete user");
                }
            },
                (error) => { toast.error("Cannot delete an invalid user") });
    }

    return (
        <>            
            <InventoryTable 
                rows={rows}
                columns={baseColumns}
                selected={selectedRows}
                actions={actions}
                setSelected={setSelectedRows}
                checkbox={false}
            
            />
            <InviteUserDialog 
                open={props.openAddDialog} 
                handleClose={props.handleAddClose} 
            />
            <ConfirmationDialog
                title="DELETE USER"
                open={deleteConfirmOpen}
                setOpen={setDeleteConfirmOpen}
                onConfirm={deleteUser}
            >
                Are you sure you want to delete {userName}?
            </ConfirmationDialog>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        tenantsDict: state.tenantsDict
    }
}

export default connect(mapStateToProps)(Users);