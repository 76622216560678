import AcquireToken from "./AcquireToken";
import { RULE_TYPES_API } from "./Endpoints";
import { setRuleAlertTypes} from '../reducer/ruleAlert';

async function GetRuleAlertTypes(instance, account) {
    const accessToken = await AcquireToken(instance, account);
    if (accessToken) {
        return (await fetch(
            RULE_TYPES_API,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
            }
        ))
    } else {
        return [];
    }
}

export function fetchRulesAlertTypes(instance, account) {
    return async (dispatch, getState) => {
      const response = await GetRuleAlertTypes(instance, account);
      try {
        const data = await response.json();
        // only want the relevant rules in total (exclude missnamed ones)
        data.Total = (data.BPVRule ?? 0) + (data.BootUpRule ?? 0) + (data.SCRADRule ?? 0);
        dispatch(setRuleAlertTypes(data));
      } catch (err) {
        console.log("Unable to fetch GetRuleAlertTypes data")
      }
    };
  }

export default fetchRulesAlertTypes;
