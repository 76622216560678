import Chip from '@mui/material/Chip';


// the enums for these conversions can be found in the db-library

// converts model number to it's string based on enum in DB library
export const modelNumberToString = (modelNo) => {
    if (modelNo === 0) {
        return "1.0.0";
    } else if (modelNo === 1) {
        return "2.0.0";
    } else if (modelNo === 2) {
        return "2.1.1";
    } else {
        return "UNKOWN";
    }
}

// converts model number to it's string based on enum in DB library
export const hwVersionToString = (hwVersion) => {
    if (hwVersion === 0) {
        return "3.2";
    } else if (hwVersion === 1) {
        return "3.3";
    } else if (hwVersion === 2) {
        return "3.3a";
    } else {
        return "UNKOWN";
    }
}


// powerConnectorToElement(powerConnector) returns an array of 2 elements : 
// 0th element is the name of the status (string)
// the 1-index element is the chip for the status (react element)
export function statusToElement(status) {
    switch (status) {
        case 0:
            return ["In Stock", <Chip label="In Stock" variant="outlined" size="small" className="in-stock" />];
        case 1:
            return ["Under Development", <Chip label="Development" variant="outlined" size="small" className="under-dev" />];
        case 2:
            return ["Ready For Shipping", <Chip label="Ready" variant="outlined" size="small" className="ready-for-shipping" />];
        case 3:
            return ["Shipped", <Chip label="Shipped" variant="outlined" size="small" className="shipped" />];
        case 4:
            return ["Received", <Chip label="Received" variant="outlined" size="small" className="received" />];
        case 5:
            return ["Deployed", <Chip label="Deployed" variant="outlined" size="small" className="deployed" />];
        case 6:
            return ["Unknown", <Chip label="Unknown" variant="outlined" size="small" className="unknown" />];
        case 7:
            return ["Personal Use", <Chip label="Person" variant="outlined" size="small" className="personal-use" />];
        default:
            return ["", <></>];
    }
}

// powerConnectorToElement(powerConnector) returns an array of 2 elements : 
// 0th element is the name of the connector (string)
// the 1-index element is the chip for the connector (react element)
export const powerConnectorToElement = (powerConnector) => {
    switch (powerConnector) {
        case 0:
            return ["Lenovo Slim Tip", <Chip label="Lenovo" variant="outlined" size="small" className="lenovo-connector" />];
        case 1:
            return ["Dell 4.5mm DC jack ", <Chip label="Dell" variant="outlined" size="small" className="dell-connector" />];
        case 2:
            return ["T Connector", <Chip label="T" variant="outlined" size="small" className="t-connector" />];
        case 3:
            return ["Barrel Connector", <Chip label="Barrel" variant="outlined" size="small" className="barrel-connector" />];
        default:
            return ["", <></>];
    }
}



// chipToElement(chip) returns an array of 2 elements : 
// 0th element is the name of the chip (string)
// the 1-index element is the chip element for that computer chip (react element)
export const chipToElement = (chip) => {
    switch (chip) {
        case 0:
            return ["ATSAMV71Q19", <Chip label="Q19" variant="outlined" size="small" className="q19-chip" />];
        case 1:
            return ["ATSAME70Q20", <Chip label="Q20" variant="outlined" size="small" className="q20-chip" />];
        case 2:
            return ["ATSAME70Q21", <Chip label="Q21" variant="outlined" size="small" className="q21-chip" />];
        default:
            return ["", <></>];
    }
}