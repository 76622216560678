import AcquireToken from "./AcquireToken";
import { RULE_ALERTS_API } from "./Endpoints";
import { setRulesAlert} from '../reducer/ruleAlert';

async function GetRulesAlert(instance, account) {
    const accessToken = await AcquireToken(instance, account);
    if (accessToken) {
        return (await fetch(
            RULE_ALERTS_API,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
            }
        ))
    } else {
        return [];
    }
}

export function fetchRulesAlert(instance, account) {
    return async (dispatch, getState) => {
      const rulesAlert = await GetRulesAlert(instance, account);
      try {
        const rulesStats = await rulesAlert.json();
        rulesStats.alertChange = Math.abs(
          rulesStats.currentWeek - rulesStats.previousWeek
        );
        dispatch(setRulesAlert(rulesStats));
      } catch (err) {
        console.log("Unable to fetch Alert data");
      }
    };
  }

export default fetchRulesAlert;
