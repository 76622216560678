import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import { useMsal, useAccount } from "@azure/msal-react";
import GetTenants from '../../API/GetTenants';
import PostInvite from '../../API/PostSendInvite';
import { toast } from "react-toastify";

const rolesList = [
    "User",
    "Admin",
    "SuperUser",
    "SuperAdmin"
]

const InviteUserDialog = (props) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [tenantList, setTenantList] = React.useState([]);

    const [tenant, setTenant] = React.useState('');
    const [role, setRole] = React.useState(rolesList[0]);
    const [email, setEmail] = React.useState('');

    React.useEffect(() => {
        async function getData() {
            if (account) {
                const tenants = await GetTenants(instance, account)
                setTenantList(tenants);
                setTenant(tenants[0]?.id);
            }
        }
        getData();
    }, [account, instance]);

    const handleRoleChange = (event) => {
        setRole(event.target.value)
    }

    const handleTenantChange = (event) => {
        setTenant(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handleClose = () => {
        props.handleClose();
        setRole(rolesList[0]);
        setTenant(tenantList[0]?.id);
        setEmail('');
    }

    const sendInvite = async () => {
        const toastInviteLoading = toast.loading(
            "Invite sent to email " + email,
            {
                toastId: email
            }
        );
        await PostInvite(
            instance,
            account,
            { inviteEmail: email, tenantID: tenant, role }
        ).then((response) => {
            if (!response.ok) {
                toast.dismiss(toastInviteLoading);
                toast.error(response.status == 400 ? "Invalid email address" : "Unable to send invite");
            }
        }, (error) => {
            toast.dismiss(toastInviteLoading);
            toast.error("Unable to send invite");
        });
        handleClose();
    }

    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth>
            <DialogTitle>INVITE A NEW USER</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} width={1000}>
                    <Grid item xs={3}>
                        <TextField
                            autoFocus
                            select
                            margin="dense"
                            id="tenant"
                            label="Tenant"
                            fullWidth
                            variant="outlined"
                            defaultValue={tenant}
                            value={tenant}
                            onChange={handleTenantChange}
                            required
                        >
                            {
                                tenantList.map(({ id, name }) =>
                                    <MenuItem value={id}>{name}</MenuItem>
                                )
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoFocus
                            select
                            margin="dense"
                            id="role"
                            label="Role"
                            fullWidth
                            variant="outlined"
                            defaultValue={role}
                            value={role}
                            onChange={handleRoleChange}
                            required
                        >
                            {
                                rolesList.map(role =>
                                    <MenuItem value={role}>{role}</MenuItem>
                                )
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="email"
                            label="Email"
                            fullWidth
                            variant="outlined"
                            onChange={handleEmailChange}
                            required
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleClose()}
                    variant="contained"
                    color="secondary"
                >
                    Cancel
                </Button>
                <Button
                    onClick={(sendInvite)}
                    disabled={!(tenant && role && email)} // validate email too somehow
                    variant="contained"
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default InviteUserDialog