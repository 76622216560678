import { Table, TableBody, TableContainer } from '@mui/material';
import StyledTableRow from './StyledTableRow';
import BPVRule from '../Rules/BPVRule';
import SCRADRule from '../Rules/SCRADRule';
import BootUpRule from '../Rules/BootUpRule';
import { BPV_RULE, SCRAD_RULE, BOOT_UP_RULE } from '../Rules/RuleTypes';


const RulesTable = (props) => {
    const handleEnabledChange = (rule) => {
        var newRule = rule;
        newRule.enabled = !rule.enabled;
        props.addChangedRule(newRule);
    }


    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableBody>
                    {
                        [...props.rules.values()].map(rule =>
                        (
                            <StyledTableRow>
                                {
                                    rule.type === BPV_RULE ?
                                        <BPVRule
                                            rule={rule}
                                            addChangedRule={props.addChangedRule}
                                            editsAuthorized={props.editsAuthorized}
                                            changeNumInputErrors={props.changeNumInputErrors}
                                            handleEnabledChange={handleEnabledChange}
                                        /> :
                                        rule.type === SCRAD_RULE ?
                                            <SCRADRule
                                                rule={rule}
                                                addChangedRule={props.addChangedRule}
                                                editsAuthorized={props.editsAuthorized}
                                                changeNumInputErrors={props.changeNumInputErrors}
                                                handleEnabledChange={handleEnabledChange}
                                            /> :
                                            rule.type === BOOT_UP_RULE ?
                                                <BootUpRule rule={rule}
                                                    addChangedRule={props.addChangedRule}
                                                    editsAuthorized={props.editsAuthorized}
                                                    changeNumInputErrors={props.changeNumInputErrors}
                                                    handleEnabledChange={handleEnabledChange}
                                                /> :
                                                <>INVALID RULE</>
                                }
                            </StyledTableRow>
                        )
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
};

export default RulesTable;