import AcquireToken from "./AcquireToken";
import { PALISADE_API } from "./Endpoints";

async function GetPalisadeInfo(instance, account, id) {
    const accessToken = await AcquireToken(instance, account);
    if (accessToken) {
        return (await fetch(
            `${PALISADE_API}/${id}/info`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            }
        )).json()
    } else {
        return null;
    }
}

export default GetPalisadeInfo;