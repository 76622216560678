import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import StyledTableRow from './StyledTableRow';
import Checkbox from '@mui/material/Checkbox';
import EnhancedTableHead from './EnhancedTableHead';
import './Table.css';
import './PalisadeInventory.css';
import { statusToElement, chipToElement } from '../Utilities/enumConversions.js';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const InventoryTable = (props) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const selected = props.selected || [];
    const setSelected = props.setSelected;
    const checkboxes = props.checkbox ?? true;
    const pagination = props.pagination ?? true;
    const tableReference = React.createRef();
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {  // Assumes unique device ids, lifted from mui sample code
        if (event.target.checked) {
            const newSelecteds = props.rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        tableReference.current && tableReference.current.scrollIntoView();
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

    return (
        <Paper>
            <TableContainer>
                <Table
                    aria-label="simple table"
                    ref={tableReference}>
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={props.rows.length}
                        cells={props.columns}
                        actions={props.actions}
                        checkbox={props.checkbox}
                    />
                    <TableBody>
                        {props.rows
                            .sort(getComparator(order, orderBy))
                            .slice(...(pagination ? [page * rowsPerPage, page * rowsPerPage + rowsPerPage] : [0]))
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <StyledTableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        p={isItemSelected}
                                    >
                                        {checkboxes ?
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    onClick={(event) => handleClick(event, row.id)}
                                                />
                                            </TableCell> :
                                            <></>}
                                        {props.columns.map((headCell) => {
                                            if (headCell.id === "status" && props.Sensors) {
                                                return row[headCell.id] ? (
                                                    <TableCell>
                                                        <Stack direction="row" justifyContent="center" alignItems="center">
                                                            <Grid container width="4rem"><CircleIcon fontSize="1rem" sx={{ color: '#3CC13B' }} /> </Grid>
                                                            <Grid container > Connected&nbsp;&nbsp;&nbsp;&nbsp;</Grid>
                                                            <Grid container></Grid>
                                                        </Stack>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell>
                                                        <Stack direction="row" justifyContent="center" alignItems="center">
                                                            <Grid container width="4rem" ><CircleIcon fontSize="1rem" sx={{ color: '#F03738' }} /> </Grid>
                                                            <Grid container > Disconnected</Grid>
                                                            <Grid container></Grid>
                                                        </Stack>
                                                    </TableCell>
                                                );
                                            } else if ((headCell.id === "status" || headCell.id === "deploymentStatus" )&& !props.Sensors) {
                                                var deploymentStatusInfo = statusToElement(row[headCell.id]);
                                                return (
                                                    <TableCell>
                                                        <Tooltip title={deploymentStatusInfo[0]} className="deployment-status-container">
                                                            {deploymentStatusInfo[1]}
                                                        </Tooltip>
                                                    </TableCell>
                                                );
                                            } else if (headCell.id === "chip" && !props.Sensors) {
                                                var chipInfo = chipToElement(row[headCell.id]);
                                                return (
                                                    <TableCell>
                                                        <Tooltip title={chipInfo[0]} className="chip-container">
                                                            {chipInfo[1]}
                                                        </Tooltip>
                                                    </TableCell>
                                                );
                                            } else {
                                                return (
                                                    <TableCell>
                                                        {row[headCell.id]}
                                                    </TableCell>
                                                );
                                            }
                                        })}
                                        {props.actions?.size ?
                                            <TableCell>
                                                <Stack direction="row" justifyContent="center" alignItems="center">
                                                    {Array.from(props.actions, ([, action]) => {
                                                        return (
                                                            action.type === "rowAction" ?
                                                                row[action.rowKey] :
                                                                <Tooltip title={action.tooltip}>
                                                                    <IconButton onClick={(event) => action.onClick(event, row.id)} >
                                                                        <Icon color={action.color}>{action.icon}</Icon>
                                                                    </IconButton>
                                                                </Tooltip>
                                                        )
                                                    })}
                                                </Stack>
                                            </TableCell> :
                                            <></>}
                                    </StyledTableRow>
                                )
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {pagination ? <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> : <></>}
        </Paper>
    );
}

export default InventoryTable;