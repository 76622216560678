import { Box, Grid } from "@mui/material";
import "../Pages/Dashboard.css";
import React from "react";

const AlertFrequencyListItem = (props) => {
    return (
        <React.Fragment>
            <Grid item>
                <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={2} sm={3} md={2}> <Box className={props.boxClass} /></Grid>
                    <Grid
                        item
                        xs={10}
                        sm={9}
                        md={10}
                    >
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >
                                <text className="alerts-chart-type-title">{props.title}</text>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <text className="alerts-chart-percent">{props.percent} %</text>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default AlertFrequencyListItem;