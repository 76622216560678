import { Box, Grid } from "@mui/material";
import "../Pages/Dashboard.css";
import React from "react";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const DeploymentStatusDropdown = (props) => {
    return (
        <React.Fragment>
            <FormControl required fullWidth sx={{ mt: 1 }} margin="dense">
                <InputLabel id="deploymentStatus-select-label">Deployment Status</InputLabel>
                <Select
                    labelId="deploymentStatus-select-label"
                    id={`deploymentStatus-select-${props.index || props.deploymentStatus || ''}`}
                    value={props.deploymentStatus}
                    label="Deployment Status"
                    onChange={props.handleChange}
                >
                    <MenuItem value={0}>{"In Stock"}</MenuItem>
                    <MenuItem value={1}>{"Under Development"}</MenuItem>
                    <MenuItem value={2}>{"Ready For Shipping"}</MenuItem>
                    <MenuItem value={3}>{"Shipped"}</MenuItem>
                    <MenuItem value={4}>{"Received"}</MenuItem>
                    <MenuItem value={5}>{"Deployed"}</MenuItem>
                    <MenuItem value={6}>{"Unknown"}</MenuItem>
                    <MenuItem value={7}>{"Personal Use"}</MenuItem>
                </Select>
            </FormControl>
        </React.Fragment>
    );
}

export default DeploymentStatusDropdown;