import { createSlice } from '@reduxjs/toolkit'

export const aggregatorStatsSlice = createSlice({
  name: 'aggregatorStats',
  initialState: [],
  reducers: {
    setAggregatorStats: (state, action) => action.payload,
  }
})

export const { setAggregatorStats } = aggregatorStatsSlice.actions;

export const aggregatorStatsReducer =  aggregatorStatsSlice.reducer;