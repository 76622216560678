import AcquireToken from "./AcquireToken";
import { PALISADE_STATS_API } from "./Endpoints";
import { setPalisadeStats } from "../reducer/palisades";

async function GetPalisadeStats(instance, account) {
  const accessToken = await AcquireToken(instance, account);
  if (accessToken) {
    return (
      await fetch(PALISADE_STATS_API, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).json();
  } else {
    return [];
  }
}

export function fetchPalisadeStats(instance, account) {
  return async (dispatch, getState) => {
    const response = await GetPalisadeStats(instance, account);
    dispatch(setPalisadeStats(response));
  };
}

export default fetchPalisadeStats;
