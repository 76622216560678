import AcquireToken from "./AcquireToken";
import { RULES_API } from "./Endpoints";

async function PutRule(instance, account, rule) {
    const accessToken = await AcquireToken(instance, account);
    if (accessToken) {
        return (await fetch(
            RULES_API,
            {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'X-Correlation-ID': `${crypto.randomUUID()}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(rule)
            }
        ))
    } else {
        return null;
    }
}

export default PutRule