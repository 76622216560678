import GetPalisades from "../API/GetPalisades"
import { createSlice } from '@reduxjs/toolkit'

export const palisadesInfoSlice = createSlice({
  name: 'palisadesInfo',
  initialState: [],
  reducers: {
    setPalisades: (state, action) => action.payload,
    addPalisade: (state, action) => {
      state.push(action.payload);
    },
    editPalisade: (state, action) => {
      const { id, newTenant, newAggregatorId, newDeploymentStatus } = action.payload;
      return state.map(palisade =>
        palisade.id === id ?
          {
            ...palisade,
            tenantId: newTenant,
            aggregatorId: newAggregatorId,
            newDeploymentStatus: newDeploymentStatus
          } : palisade
      )
    },
    deletePalisade: (state, action) => {
      const { deletePalisadeId } = action.payload;
      return state.filter(palisade =>
        palisade.id !== deletePalisadeId
      )
    },
    setPalisadeTransmission: (state, action) => {
      const { id, newTransmission } = action.payload;
      return state.map(palisade =>
        palisade.id === id ? { ...palisade, transmissionEnabled: newTransmission } : palisade
      )
    },
    setPalisadeCANTransmission: (state, action) => {
      const { id, newTransmission } = action.payload;
      return state.map(palisade =>
        palisade.id === id ? { ...palisade, canTransmissionEnabled: newTransmission } : palisade
      )
    }
  }
});

export const {
  setPalisades,
  addPalisade,
  editPalisade,
  deletePalisade,
  setPalisadeTransmission,
  setPalisadeCANTransmission
} = palisadesInfoSlice.actions;

export const palisadesInfoReducer = palisadesInfoSlice.reducer;

export function fetchPalisades(instance, account) {
  return async (dispatch, getState) => {
    const response = await GetPalisades(instance, account);
    dispatch(setPalisades(response));
  }
}

export const palisadeStatsSlice = createSlice({
  name: 'palisadeStats',
  initialState: [],
  reducers: {
    setPalisadeStats: (state, action) => action.payload,
  }
});

export const { setPalisadeStats } = palisadeStatsSlice.actions;

export const palisadesStatsReducer = palisadeStatsSlice.reducer;