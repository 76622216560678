import GetTenants from '../API/GetTenants';
import { createSlice } from '@reduxjs/toolkit'

export const tenantsInfoSlice = createSlice({
  name: 'tenantsInfo',
  initialState: [],
  reducers: {
    setTenantsInfo: (state, action) => action.payload,
  }
});

export const { setTenantsInfo } = tenantsInfoSlice.actions;

export const tenantsInfoReducer =  tenantsInfoSlice.reducer;

export const tenantsDictSlice = createSlice({
  name: 'tenantsDict',
  initialState: {},
  reducers: {
    setTenantsDict: (state, action) => action.payload,
  }
})

export const { setTenantsDict } = tenantsDictSlice.actions;

export const tenantsDictReducer =  tenantsDictSlice.reducer;

const convertToDictionary = (tenantData, dispatch) => {
  let tenantDict = {};
  for(let i=0;i<tenantData.length;i++){
    tenantDict[tenantData[i].id] = tenantData[i].name;
  }
  dispatch(setTenantsDict(tenantDict));
}

export function fetchTenants(instance, account) {
  return async (dispatch, getState) => {
      const response = await GetTenants(instance, account);
      dispatch(setTenantsInfo(response))
      convertToDictionary(response, dispatch);
  }
}