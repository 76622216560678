import { REDEEM_INVITATION_API } from "./Endpoints";

async function PostRedemption(redemption) {
    return (await fetch(
        REDEEM_INVITATION_API,
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(redemption),
        }
    ));
}

export default PostRedemption;