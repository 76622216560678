import { authenticationParameters } from "../authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

async function AcquireToken(instance, account) { 

    if (account) {
        const request = {
            scopes: authenticationParameters.scopes,
            account: account
        };

        return await instance.acquireTokenSilent(request)
            .then(response => response.accessToken)
            .catch(error => {
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return instance.acquireTokenRedirect(request)
                }
            });
    } else {
        return null;
    }
}

export default AcquireToken;