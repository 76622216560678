import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useMsal, useAccount } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import Grid from "@mui/material/Grid";
import { ThemeProvider } from "@mui/material/styles";

import Navbar from "./Components/Header/Navbar";
import SideMenu from "./Components/Menu/SideMenu";
import Dashboard from "./Components/Pages/Dashboard";
import Sensors from "./Components/Pages/Sensors";
import Inventory from "./Components/Pages/Inventory";
import Configurations from "./Components/Pages/Configurations";
import Settings from "./Components/Pages/Settings";
import UserManagement from "./Components/Pages/UserManagement";
import Rules from "./Components/Pages/Rules";
import { fetchTenants } from "./reducer/tenants";
import PalitronicaTheme from "./PalitronicaTheme";
import { setLoginInfo } from './reducer/login';
import InitSignalR from "./API/InitSignalR";

import "./App.css";

export function App() {

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  var role = "";
  if (account) {
      role = account.idTokenClaims?.extension_appRole?.toLowerCase();
  }
  const [apiData, setApiData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (account) {
      dispatch(fetchTenants(instance, account));
      dispatch(setLoginInfo({ instance, account }));
      instance
        .acquireTokenSilent({
          scopes: [
            `https://${process.env.REACT_APP_AUTH_ENDPOINT}/${process.env.REACT_APP_AZ_ADB2C_BE_CLID}/Devices.Read`,
          ],
          account: account,
        })
        .then((response) => {
          if (response) {
            const decodedToken = jwt_decode(response.accessToken);
            setApiData(JSON.stringify(decodedToken));
          }
        });
    }
  }, [account, instance, dispatch]);

  // Start SignalR connection
  useEffect(() => {
    if (account) {
      InitSignalR(instance, account);
    }
  }, [account, instance])

  const tab2 = "protected-assets";
  const tab3 = "rules";
  const tab4 = "sensors";
  const tab5 = "inventory";
  const tab6 = "users";
  const tab7 = "settings";


  return (
    <div className="App">
      <ThemeProvider theme={PalitronicaTheme}>
        <Navbar loginInfo={apiData} />
        <Grid container className="app-container">
          <Grid item xs={3} md={3} className="app-container-left">
            <SideMenu
              loginInfo={apiData}
              tab2={tab2}
              tab3={tab3}
              tab4={tab4}
              tab5={tab5}
              tab6={tab6}
              tab7={tab7}
            />
          </Grid>
          <Grid className="app-container-content" item xs={9} md={9}>
            <Routes>
              <Route path={`/${tab2}`} element={<Configurations />} />
              <Route path={`/${tab3}`} element={<Rules />} />
              <Route path={`/${tab4}`} element={<Sensors />} />
              {role === "superuser" || role === "superadmin" ? <Route path={`/${tab5}`} element={<Inventory />} /> : <></>}
              {role === "superuser" || role === "superadmin" ? <Route path={`/${tab6}`} element={<UserManagement />} /> : <></>}
              <Route path={`/${tab7}`} element={<Settings />} />
              <Route path="/" element={<Dashboard />} />
            </Routes>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default App;
