import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useMsal, useAccount } from "@azure/msal-react";
import GetTenants from '../../API/GetTenants';
import PostAggregator from '../../API/PostAggregator';
import { toast } from "react-toastify";
import DeploymentStatusDropdown from '../ListItems/DeploymentStatusDropdown';

const AddAggregatorDialog = (props) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [tenantList, setTenantList] = React.useState([]);

    const [aggregator, setAggregator] = React.useState('');
    const [tenant, setTenant] = React.useState('');
    const [hardware, setHardware] = React.useState('');
    const [hostname, setHostname] = React.useState('');
    const [macAddress, setMacAddress] = React.useState('');
    const [vpnipAddress, setVpnipAddress] = React.useState('');
    const [deploymentStatus, setDeploymentStatus] = React.useState('');

    React.useEffect(() => {
        async function getData() {
            if (account) {
                const tenants = await GetTenants(instance, account)
                setTenantList(tenants);
                setTenant(tenants[0]?.id);
            }
        }
        getData();
    }, [account, instance]);

    const handleAggregatorChange = (event) => {
        setAggregator(event.target.value)
    }

    const handleTenantChange = (event) => {
        setTenant(event.target.value);
    };

    const handleHardwareChange = (event) => {
        setHardware(event.target.value)
    }

    const handleHostnameChange = (event) => {
        setHostname(event.target.value)
    }

    const handleMacAdressChange = (event) => {
        setMacAddress(event.target.value)
    }
    const handleVpnipAdressChange = (event) => {
        setVpnipAddress(event.target.value)
    }
    const handleDeploymentStatusChange = (event) => {
        setDeploymentStatus(event.target.value)
    }

    const handleClose = () => {
        props.handleClose();
        setAggregator('');
        setTenant(tenantList[0]?.id);
        setHardware('');
        setHostname('');
        setMacAddress('');
        setVpnipAddress('');
        setDeploymentStatus('');
    }

    const addAggregator = async () => {
        await PostAggregator(instance, account, { Id: aggregator, TenantId: tenant, Hardware: hardware, Hostname: hostname, MACAddress: macAddress, VpnipAddress: vpnipAddress, DeploymentStatus: deploymentStatus })
            .then((response) => {
                if (response.ok) {
                    toast.success("Successfully added aggregator");
                    response.json().then(
                        (newAggregator) => {
                            props.addRow(newAggregator);
                        }
                    )
                } else {
                    toast.error("Unable to add aggregator");
                }
            }, (error) => {
                toast.error("Unable to add aggregator");
            });
        handleClose();
    }

    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth>
            <DialogTitle>ADD NEW AGGREGATOR</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} columns={16}>
                    <Grid item xs={4}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="aggregator-id"
                            label="Aggregator ID"
                            helperText="Hub Aggregator ID"
                            fullWidth
                            variant="outlined"
                            onChange={handleAggregatorChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="hardware-type"
                            label="Hardware"
                            helperText="Hardware Type"
                            fullWidth
                            variant="outlined"
                            onChange={handleHardwareChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="Hostname"
                            label="Hostname"
                            fullWidth
                            variant="outlined"
                            onChange={handleHostnameChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl required fullWidth sx={{ mt: 1 }}>
                            <InputLabel id="tenant-select-label">Tenant</InputLabel>
                            <Select
                                labelId="tenant-select-label"
                                id="tenant-select"
                                value={tenant}
                                label="Tenant"
                                onChange={handleTenantChange}
                            >
                                {
                                    tenantList.map(({ id, name }) =>
                                        <MenuItem value={id}>{name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="macAddress"
                            label="Mac Address"
                            fullWidth
                            variant="outlined"
                            onChange={handleMacAdressChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="vpnipAddress"
                            label="VPN IPAddress"
                            fullWidth
                            variant="outlined"
                            onChange={handleVpnipAdressChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DeploymentStatusDropdown
                                handleChange={handleDeploymentStatusChange}
                                deploymentStatus={deploymentStatus}
                        />                  
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={addAggregator}
                    disabled={!(aggregator && tenant && hardware && macAddress && vpnipAddress && deploymentStatus)}
                    variant="contained"
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddAggregatorDialog