import AcquireToken from "./AcquireToken";
import { TARGET_BEATS_API } from "./Endpoints";

async function GetTargetBeatsConfig(instance, account, tenantId, targetId) {
    const accessToken = await AcquireToken(instance, account);
    if (accessToken) {
        return (await fetch(
            `${TARGET_BEATS_API}/${tenantId}/${targetId}/beat`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
            }
        ))
    } else {
        return null;
    }
}

export default GetTargetBeatsConfig;