import React from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import { AddCircle, Edit } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import AggregatorInventory from "../Tables/AggregatorInventory";
import PalisadeInventory from "../Tables/PalisadeInventory";
import InventorySummary from "../Tables/InventorySummary";
import { Card, Select } from "@mui/material";
import { useMsal, useAccount } from "@azure/msal-react";
import { ToastContainer } from "react-toastify";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import GetTenants from "../../API/GetTenants";
import { inventoryTenantId } from "../Utilities/api";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Inventory = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [value, setValue] = React.useState(0);  // Selected Tab
    const [displayAddEdit, setDisplayAddEdit] = React.useState("none");
    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [anySelected, setAnySelected] = React.useState(false);
    const [superAccount, setSuperAccount] = React.useState(false);
    const [tenantList, setTenantList] = React.useState([]);
    const [tenant, setTenant] = React.useState('');

    const handleAddOpen = () => {
        setAddOpen(true);
    };

    const handleAddClose = () => {
        setAddOpen(false);
    };

    const handleEditOpen = () => {
        setEditOpen(true);
    };

    const handleEditClose = () => {
        setEditOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleTenantChange = (event) => {
        setTenant(event.target.value);
        setAnySelected(false);
    }

    React.useEffect(() => {
        async function getData() {
            if (account) {
                const tenants = await GetTenants(instance, account)
                setTenantList(tenants);
                setTenant(inventoryTenantId || tenants[0]?.id || '');
            }
        }
        getData();
        if (account) {
            const role = account.idTokenClaims?.extension_appRole?.toLowerCase();
            if (role === "superadmin") {
                setDisplayAddEdit("flex");
                setSuperAccount(true);
            } else if (role === "admin") {
                setSuperAccount(false);
            } else if (role === "superuser") {
                setSuperAccount(true);
            } else if (role === "user") {
                setSuperAccount(false);
            }
        }
    }, [account, instance]);

    return (
        <Box sx={{ padding: '20px', paddingBottom: '0' }}>
            <Card>
                <Grid container sx={{ height: '75px', paddingBottom: '5px' }} alignItems="flex-end"  >
                    <Grid item xs={6} sx={{ paddingBottom: '5px' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Palisade Sensors" {...a11yProps(0)} />
                            <Tab label="Aggregators" {...a11yProps(1)} />
                            <Tab label="Summary" {...a11yProps(2)} />
                        </Tabs>
                    </Grid>
                    <Grid item xs={6} sx={{ display: `flex`, alignItems: "flex-end", justifyContent: "flex-end", paddingBottom: '5px' }}>
                        {
                            superAccount &&
                            value === 0 &&
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <InputLabel id="tenant-select" sx={{}}>Tenant</InputLabel>
                                <Select
                                    labelId="tenant-select"
                                    id="tenant-select"
                                    label="Tenant"
                                    onChange={handleTenantChange}
                                    value={tenant}
                                >
                                    {
                                        tenantList.map(({ id, name }) =>
                                            <MenuItem value={id}>{name}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        }
                        <IconButton onClick={handleAddOpen} sx={{ display: value===2 ? 'none' : displayAddEdit, paddingRight: '20px' }}>
                            <AddCircle />
                        </IconButton>
                        <IconButton onClick={handleEditOpen} disabled={!anySelected} sx={{ display: value===2 ? 'none' : displayAddEdit }}>
                            <Edit />
                        </IconButton>
                    </Grid>
                </Grid>
                <TabPanel value={value} index={0}>
                    <PalisadeInventory
                        openAddDialog={addOpen && value === 0}
                        handleAddClose={handleAddClose}
                        openEditDialog={editOpen && value === 0}
                        handleEditClose={handleEditClose}
                        setAnySelected={setAnySelected}
                        tenant={tenant}
                        superAccount={superAccount}
                        anySelected={anySelected}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <AggregatorInventory
                        openAddDialog={addOpen && value === 1}
                        handleAddClose={handleAddClose}
                        openEditDialog={editOpen && value === 1}
                        handleEditClose={handleEditClose}
                        setAnySelected={setAnySelected}
                    />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <InventorySummary/>
                </TabPanel>
            </Card>
            <ToastContainer />
        </Box>
    );
}
export default Inventory;