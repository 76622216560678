import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import MuiPopover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import InventoryTable from './InventoryTable';
import { useMsal, useAccount } from "@azure/msal-react";
import GetAggregators from "../../API/GetAggregators";
import AddAggregatorDialog from "../Dialog/AddAggregatorDialog";
import EditAggregatorDialog from "../Dialog/EditAggregatorDialog";
import PutAggregator from "../../API/PutAggregator";
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import DeleteAggregator from '../../API/DeleteAggregator';
import GetAggregatorConnectionString from '../../API/GetAggregatorConnectionString';
import { toast } from "react-toastify";


const Popover = withStyles((theme) => ({
    root: {},
    paper: {
        backgroundColor: theme.palette.primary.main,
    },
}))(MuiPopover)

const baseColumns = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Device Id',
    },
    {
        id: 'tenant',
        numeric: true,
        disablePadding: false,
        label: 'Tenant',
    },
    {
        id: 'macAddress',
        numeric: false,
        disablePadding: false,
        label: 'Mac Address',
    },
    {
        id: 'hostname',
        numeric: false,
        disablePadding: false,
        label: 'Hostname',
    },
    {
        id: 'vpnipAddress',
        numeric: false,
        disablePadding: false,
        label: 'VPN IP',
    },
    {
        id: 'hardware',
        numeric: false,
        disablePadding: false,
        label: 'Hardware',
    },
    {
        id: 'deploymentStatus',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    }

];

const baseActions = new Map([
    [
        'key',
        {
            icon: "vpn_key",
            tooltip: "Connection String",
            color: "inherit",
            onClick: () => { },
        }
    ],
    [
        'delete',
        {
            icon: "delete",
            tooltip: "Delete",
            color: "error",
            onClick: () => { },
        }
    ],
]);

const AggregatorInventory = (props) => {
    const { tenantsDict } = props;
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [rows, setRows] = useState([]);
    const [cols, setCols] = useState(baseColumns);
    const [actions, setActions] = useState(baseActions);
    const [selectedRows, setSelectedRows] = React.useState([]); // We need these here for editing
    const [deleteAggregatorId, setDeleteAggregatorId] = React.useState();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [connectionString, setConnectionString] = React.useState("");

    const addRow = ({ id, tenantId, macAddress, hostname, hardware, vpnipAddress, deploymentStatus }) => {
        setRows(rows => [...rows, {
            id, tenant: tenantsDict[tenantId], macAddress, hostname, hardware, vpnipAddress, deploymentStatus
        }]);
    }

    if (actions.get('delete')) {
        actions.get('delete').onClick = (event, id) => {
            setDeleteAggregatorId(id);
            setDeleteConfirmOpen(true);
        }
    }

    if (actions.get('key')) {
        actions.get('key').onClick = (event, id) => {
            GetAggregatorConnectionString(instance, account, id)
                .then(
                    response => {
                        if (response.ok) {
                            response.json().then(
                                (jsonResponse) => {
                                    if(jsonResponse.connectionString !=='') {
                                        setConnectionString(jsonResponse.connectionString);
                                    }
                                    else{
                                        toast.warning("Connection string is empty"); 
                                    }
                                }
                            )
                            
                        } else {
                            toast.error("Unable to get connection string");
                        }

                    },
                    (error) => { toast.error("Unable to get connection string"); });
                setAnchorEl(event.currentTarget);
        }
    }

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setConnectionString("");
    };
    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    const deleteAggregator = () => {
        DeleteAggregator(instance, account, deleteAggregatorId).then((response) => {
            if (response.ok) {
                toast.success("Successfully deleted aggregator");
                setRows(rows.filter(aggregator =>
                    aggregator.id !== deleteAggregatorId
                )
                );
            } else {
                toast.error("Unable to delete aggregator");
            }
        }, (error) => {
            toast.error("Unable to delete aggregator");
        });
    }

    useEffect(() => {
        async function getData() {
            if (account) {
                const aggregators = await GetAggregators(instance, account);
                const role = account.idTokenClaims?.extension_appRole?.toLowerCase();
                setRows(aggregators.map(
                    ({ id, tenantId, hostname, macAddress, hardware, vpnipAddress, deploymentStatus }) => ({
                        id,
                        tenant: tenantsDict[tenantId],
                        hostname,
                        macAddress,
                        hardware, 
                        vpnipAddress,
                        deploymentStatus,
                    })
                ));
                if (role !== "superadmin" && role !== "superuser") {
                    setCols([...baseColumns.slice(0, 1), ...baseColumns.slice(2, 3)]);
                }
                if (role !== "superadmin") {
                    actions.delete('delete');
                    actions.delete('key');
                    setActions(actions);
                }
            }
        }
        getData();
    }, [account, instance]);

    useEffect(() => {
        props.setAnySelected(selectedRows.length)
    }, [selectedRows])

    const editData = async (newTenant, newVpnipAddress, newHostname, newDeploymentStatus) => {
        rows.forEach(async ({ id, tenant, hardware, macAddress}, index) => {
            if (selectedRows.includes(id)) {
                await PutAggregator(instance, account, { Id: id, TenantId: newTenant, Hardware: hardware, Hostname: newHostname, MacAddress: macAddress, VpnipAddress: newVpnipAddress, DeploymentStatus: newDeploymentStatus }).then(
                    (response) => {
                        if (response.ok) {
                            toast.success("Successfully edited aggregator");
                            setRows(rows => [
                                ...rows.slice(0, index),
                                { ...rows[index], tenant: tenant },
                                ...rows.slice(index + 1, rows.length)
                            ]);
                        } else {
                            toast.error("Unable to edit aggregator");
                        }
                    }, (error) => {
                        toast.error("Unable to edit aggregator");
                    }
                )
            }
        }
        )
        props.handleEditClose()
    }

    return (
        <>
            <InventoryTable
                rows={rows}
                columns={cols}
                actions={actions}
                selected={selectedRows}
                setSelected={setSelectedRows}
                Sensors={false} />
            <ConfirmationDialog
                title="DELETE AGGREGATOR"
                open={deleteConfirmOpen}
                setOpen={setDeleteConfirmOpen}
                onConfirm={deleteAggregator}
            >
                Are you sure you want to delete Aggregator {deleteAggregatorId}?
            </ConfirmationDialog>
            {connectionString && (
                <Popover
                    id={popoverId}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Typography sx={{ p: 1 }} color="white">{connectionString}
                        <span style={{padding: '10px'}}>
                            <CopyToClipboard text={connectionString} onCopy={()=>{toast.success("Connection String Copied")}}>
                                <IconButton aria-label="copy" sx={{color: "white"}}>
                                    <ContentCopyIcon />
                                </IconButton>
                            </CopyToClipboard>
                        </span>
                    </Typography>

                </Popover>
            )}
            <AddAggregatorDialog
                open={props.openAddDialog}
                handleClose={props.handleAddClose}
                addRow={addRow}
            />
            <EditAggregatorDialog
                open={props.openEditDialog}
                handleClose={props.handleEditClose}
                editData={editData}
                selectedRows={selectedRows}
                rows={rows}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        tenantsDict: state.tenantsDict
    }
}

export default connect(mapStateToProps)(AggregatorInventory);