import AcquireToken from "./AcquireToken";
import { PALISADE_API } from "./Endpoints";

async function PutCANTransmission(instance, account, palisadeId, canTransmissionEnabled) {
    const accessToken = await AcquireToken(instance, account);
    if (accessToken) {
        return (await fetch(
            `${PALISADE_API}/${palisadeId}/can-transmission?canTransmissionEnabled=${canTransmissionEnabled}`,
            {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
            }
        ))
    } else {
        return null;
    }
}

export default PutCANTransmission