import { NOTIFICATION_SETTING } from './Endpoints';
import AcquireToken from "./AcquireToken";

async function GetSettings(instance, account) {
    const accessToken = await AcquireToken(instance, account);
    if (accessToken) {
        return (await fetch(
            NOTIFICATION_SETTING,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
            }
        )).json()
    } else {
        return [];
    }
}

export default GetSettings;
