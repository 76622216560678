import React from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import { AddCircle } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { ToastContainer } from "react-toastify";

import Targets from "../Tables/Targets";
import { Card } from "@mui/material";

import { useMsal, useAccount } from "@azure/msal-react";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Configurations = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [value, setValue] = React.useState(0);
  const [displayAddEdit, setDisplayAddEdit] = React.useState("none");
  const [addOpen, setAddOpen] = React.useState(false);

  const handleAddOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (account) {
      const role = account.idTokenClaims?.extension_appRole?.toLowerCase();
      if (role === "superadmin" || role === "admin") {
        setDisplayAddEdit("flex");
      }
    }
  }, [account, instance]);

  return (
    <Box sx={{ padding: '20px', paddingBottom: '0' }}>
      <Card>
        <Grid container>
          <Grid item xs={6}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Targets" {...a11yProps(0)} />
            </Tabs>
          </Grid>
          <Grid item xs={6} sx={{ display: `${displayAddEdit}`, alignItems: "center", justifyContent: "flex-end" }}>
            <IconButton onClick={handleAddOpen}>
              <AddCircle />
            </IconButton>
          </Grid>
        </Grid>
        <TabPanel value={value} index={0}>
          <Targets
            openAddDialog={addOpen && value === 0}
            handleAddClose={handleAddClose}
          />
        </TabPanel>
      </Card>
      <ToastContainer />
    </Box>
  );
}
export default Configurations;