import React from "react";
import { connect } from "react-redux";
import { Box, Grid, Paper, Stack } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import moment from "moment";

import "./Dashboard.css";
import fetchPalisadeStats from "../../API/GetPalisadeStats";
import fetchAggregatorStats from "../../API/GetAggregatorStats";
import fetchEnergyData from "../../API/GetEnergyData";
import { PieChart } from "react-minimal-pie-chart";
import fetchRulesAlertTypes from "../../API/GetRuleAlertTypes";
import fetchRulesAlert from "../../API/GetRulesAlert";
import GetNotificationEvents from "../../API/GetNotificationEvents";
import AlertFrequencyListItem from "../ListItems/AlertFrequencyListItem";
import { translateNotificationEvents } from "../Utilities/TranslationFile";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BPVRule = "BPVRule";
const SCRADRule = "SCRADRule";
const BootUpRule = "BootUpRule";

const spreadingRansomware = "Spreading Ransomware";
const BPV = "Boot Process Verification";
const unscheduledBootup = "Unscheduled Bootup";
const totalString = "Total";

const currentWeek = "currentWeek";
const previousWeek = "previousWeek";
const alertChange = "alertChange";

export const energyUsageChartOptions = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: "Energy Usage",
      align: "start",
      padding: {
        top: 1,
        bottom: 22,
      },
      font: {
        size: 16,
      },
      color: "#344767",
    },
    legend: {
      display: false,
    },
  },
};

const plugins = [
  {
    afterDraw: function (chart) {
      if (chart.data.datasets[0].data.length < 1) {
        let ctx = chart.ctx;
        let width = chart.width;
        let height = chart.height;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "grey";
        ctx.fillText("No data", width / 2, height / 2 + 5);
        ctx.restore();
      }
    },
  },
];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationEvents: null,
    };
  }

  displayDateTime = (timestamp) => {
    const newDate = moment(timestamp).format(" Do MMMM, h:mm:ss A");
    return <>{newDate}</>;
  };

  displayEvents = () => {
    const { notificationEvents } = this.state;
    if (notificationEvents) {
      return notificationEvents.map((event) => {
        const translatedEvent = translateNotificationEvents(event);
        return (
          <TimelineItem classname="notification-event-item">
            <TimelineSeparator className="timeline-separator">
              <TimelineDot
                style={{ backgroundColor: `${translatedEvent.iconColor}` }}
              >
                <span className="material-icons">{translatedEvent.icon}</span>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className="notification-event-content">
              {translatedEvent.message}
              <div className="date-time">
                {this.displayDateTime(event.creationTimestamp)}
              </div>
            </TimelineContent>
          </TimelineItem>
        );
      });
    }
    return null;
  };

  render() {
    const {
      palisadeStats,
      loginInfo,
      aggregatorStats,
      energyUsageData,
      alertTypes,
      rulesAlertStats,
    } = this.props;
    const { instance, account } = loginInfo;
    const { energyData, energyChangeOverPastWeek, energyUsageChartData } =
      energyUsageData;
    let { notificationEvents } = this.state;


    if (loginInfo && palisadeStats.length === 0) {
      this.props.fetchPalisadeStats(instance, account);
    }

    if (loginInfo && aggregatorStats.length === 0) {
      this.props.fetchAggregatorStats(instance, account);
    }

    if (loginInfo && notificationEvents === null) {
      GetNotificationEvents(instance, account).then(
        (response) => {
          if (response.ok) {
            response.json().then((jsonResponse) => {
              this.setState({ notificationEvents: jsonResponse });
            });
          } else {
          }
        },
        (error) => {}
      );
    }

    if (loginInfo && energyData === null) {
      this.props.fetchEnergyData(instance, account);
    }

    if (loginInfo && alertTypes.length === 0) {
      debugger;
      this.props.fetchRulesAlertTypes(instance, account);
    }

    if (loginInfo && rulesAlertStats.length === 0) {
      this.props.fetchRulesAlert(instance, account);
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            height: "100%",
          },
        }}
      >
        <Grid
          container
          item
          direction="row"
          style={{ height: "fit-content", marginTop: "30px" }}
          spacing={2}
        >
          <Grid item xs={3} className="report-card palisades" spacing={2}>
            <Paper className="paper" elevation={2}>
              <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={4}>
                  <Box className="box">
                    <span className="material-icons">sensors</span>
                  </Box>
                </Grid>
                <Grid item xs={8} spacing={1} className="content-display">
                  <div className="card-heading">Sensors</div>
                  <div className="count">
                    {palisadeStats.totalCount ? palisadeStats.totalCount : "-"}
                  </div>
                </Grid>
              </Grid>
              <div className="btm-part-alert-card">
                <hr style={{ margin: "0" }} />
                {palisadeStats.disconnectedCount > 0 ? (
                  <div className={`status disconnected`}>
                    {palisadeStats.disconnectedCount} Disconnected
                  </div>
                ) : (
                  <div className={`status connected`}>All Connected</div>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={3} className="report-card aggregators" spacing={2}>
            <Paper className="paper" elevation={2}>
              <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={4}>
                  <Box className="box">
                    <span className="material-icons">device_hub</span>
                  </Box>
                </Grid>
                <Grid item xs={8} spacing={1} className="content-display">
                  <div className="card-heading">Aggregators</div>
                  <div className="count">
                    {aggregatorStats.totalCount
                      ? aggregatorStats.totalCount
                      : "-"}
                  </div>
                </Grid>
              </Grid>
              <div className="btm-part-alert-card">
                <hr style={{ margin: "0" }} />
                {palisadeStats.disconnectedCount > 0 ? (
                  <div className={`status disconnected`}>
                    {aggregatorStats.disconnectedCount} Disconnected
                  </div>
                ) : (
                  <div className={`status connected`}>All Connected</div>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={3} className="report-card energy-usage" spacing={2}>
            <Paper className="paper" elevation={2}>
              <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={4}>
                  <Box className="box">
                    <span className="material-icons">eco</span>
                  </Box>
                </Grid>
                <Grid item xs={8} spacing={1} className="content-display">
                  <div className="card-heading">Energy Usage kWh</div>
                  <div className="count">
                    {energyData && energyData.length > 0
                      ? energyData.at(-1).toFixed(2)
                      : "-"}
                  </div>
                </Grid>
              </Grid>
              <div className="btm-part-alert-card">
                <hr style={{ margin: "0" }} />
                {energyChangeOverPastWeek > 0 ? (
                  <div className={`alerts`}>
                    <span className={`more alerts`}>
                      {"+"}
                      {energyChangeOverPastWeek}
                      {"% "}
                    </span>
                    than last week
                  </div>
                ) : energyChangeOverPastWeek < 0 ? (
                  <div className={`alerts`}>
                    <span className={`less alerts`}>
                      {energyChangeOverPastWeek}
                      {"% "}
                    </span>
                    than last week
                  </div>
                ) : (
                  <div className="alerts">Similar to last week</div>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={3} className="report-card rules-stats" spacing={2}>
            <Paper className="paper" elevation={2}>
              <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={4}>
                  <Box className="box">
                    <span className="material-icons">error</span>
                  </Box>
                </Grid>
                <Grid item xs={8} spacing={1} className="content-display">
                  <div className="card-heading">Security Alerts</div>
                  <div className="count">
                    {rulesAlertStats[currentWeek] || "-"}
                  </div>
                </Grid>
              </Grid>
              <div className="btm-part-alert-card">
                <hr style={{ margin: "0" }} />
                {rulesAlertStats[currentWeek] >
                rulesAlertStats[previousWeek] ? (
                  <div className={`alerts`}>
                    <span className={`more alerts`}>
                      {rulesAlertStats[alertChange]}{" "}
                    </span>
                    more alerts than last week
                  </div>
                ) : rulesAlertStats[currentWeek] <
                  rulesAlertStats[previousWeek] ? (
                  <div className={`alerts`}>
                    <span className={`less alerts`}>
                      {rulesAlertStats[alertChange]}{" "}
                    </span>
                    less alerts than last week
                  </div>
                ) : (
                  <div className="alerts">Similar to last week</div>
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Grid container item direction="row" spacing={2}>
          <Grid
            item
            xs={7}
            sm={7}
            md={7}
            spacing={2}
            style={{ paddingBottom: "1vh" }}
          >
            <Stack spacing={2}>
              <Grid item className="report-card alerts-chart">
                <Paper className="alerts-chart-paper" elevation={2}>
                  <Grid
                    container
                    item
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <div className="large-card-heading">Alerts by Type</div>
                      </Grid>
                      <Grid item>
                        <div className="large-card-secondary-heading">
                          Last 30 days
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={5} sm={5} md={5}>
                        <PieChart
                          lineWidth={5}
                          label={({ x, y, dx, dy, dataEntry }) =>
                            dataEntry.title === spreadingRansomware ? (
                              <text
                                x={x}
                                y={y - 5}
                                dx={dx}
                                dy={dy}
                                dominant-baseline="central"
                                text-anchor="middle"
                                className="alerts-chart-total-count"
                              >
                                {alertTypes[totalString] || 0}
                              </text>
                            ) : dataEntry.title === BPV ? (
                              <text
                                x={x}
                                y={y + 7}
                                dx={dx}
                                dy={dy}
                                dominant-baseline="central"
                                text-anchor="middle"
                                className="alerts-chart-total-secondary"
                              >
                                Alerts
                              </text>
                            ) : (
                              ""
                            )
                          }
                          labelPosition={0}
                          data={[
                            {
                              title: spreadingRansomware,
                              value: alertTypes[SCRADRule] || 0,
                              color: "#313136",
                            },
                            {
                              title: BPV,
                              value: alertTypes[BPVRule] || 0,
                              color: "#E91F63",
                            },
                            {
                              title: unscheduledBootup,
                              value: alertTypes[BootUpRule] || 0,
                              color: "#318BEB",
                            },
                          ]}
                        />
                      </Grid>
                      <Grid
                        item
                        justifyContent="space-between"
                        alignItems="center"
                        className="alerts-list"
                        xs={6}
                        sm={6}
                        md={6}
                        style={{ height: "100%" }}
                      >
                        <Grid container direction="column" spacing={1}>
                          <AlertFrequencyListItem
                            title={spreadingRansomware + ":"}
                            percent={
                              alertTypes[totalString] === 0
                                ? 0
                                : Math.round(
                                    (alertTypes[SCRADRule] * 100) /
                                      alertTypes[totalString]
                                  ) || 0
                            }
                            boxClass="alerts-box spreading-ransomware"
                          />
                          <Grid item>
                            <div className="alerts-type-divider" />
                          </Grid>

                          <AlertFrequencyListItem
                            title={BPV + ":"}
                            percent={
                              alertTypes[totalString] === 0
                                ? 0
                                : Math.round(
                                    (alertTypes[BPVRule] * 100) /
                                      alertTypes[totalString]
                                  ) || 0
                            }
                            boxClass="alerts-box boot-process"
                          />

                          <Grid item>
                            <div className="alerts-type-divider" />
                          </Grid>
                          <AlertFrequencyListItem
                            title={unscheduledBootup + ":"}
                            percent={
                              alertTypes[totalString] === 0
                                ? 0
                                : Math.round(
                                    (alertTypes[BootUpRule] * 100) /
                                      alertTypes[totalString]
                                  ) || 0
                            }
                            boxClass="alerts-box unscheduled-bootup"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item className="report-card alerts-chart" spacing={2}>
                <Paper
                  className="alerts-chart-paper"
                  elevation={2}
                  style={{ padding: "1.2em" }}
                >
                  <Line
                    options={energyUsageChartOptions}
                    data={energyUsageChartData}
                    plugins={plugins}
                  />
                </Paper>
              </Grid>
            </Stack>
          </Grid>
          <Grid
            item
            xs={5}
            sm={5}
            md={5}
            className="recent-events-card"
            spacing={2}
          >
            <Paper className="recent-events-paper" elevation={2}>
              <Grid item>
                <div className="large-card-heading">Recent Events</div>
              </Grid>
              <Grid item>
                <div className="event-card-body">
                  <Timeline style={{ margin: 0 }}>
                    {this.displayEvents()}
                  </Timeline>
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    palisadeStats: state.palisadeStats,
    loginInfo: state.loginInfo,
    aggregatorStats: state.aggregatorStats,
    energyUsageData: state.energyUsageData,
    rulesAlertStats: state.rulesAlertStats,
    alertTypes: state.rulesAlertTypes,
  };
};

export default connect(mapStateToProps, {
  fetchPalisadeStats,
  fetchAggregatorStats,
  fetchEnergyData,
  fetchRulesAlertTypes,
  fetchRulesAlert,
})(Dashboard);