import AcquireToken from "./AcquireToken";
import { TARGET_API } from "./Endpoints";

async function GetTargets(instance, account) {
    const accessToken = await AcquireToken(instance, account);
    if (accessToken) {
        return (await fetch(
            TARGET_API,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
            }
        )).json()
    } else {
        return [];
    }
}

export default GetTargets;