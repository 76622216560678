import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useMsal, useAccount } from "@azure/msal-react";
import GetTenants from '../../API/GetTenants';
import DeploymentStatusDropdown from '../ListItems/DeploymentStatusDropdown';
import TextField from '@mui/material/TextField';

const EditAggregatorDialog = (props) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [tenantList, setTenantList] = React.useState([]);

    const [tenant, setTenant] = React.useState('');
    const [hostname, setHostname] = React.useState('');
    const [vpnipAddress, setVpnipAddress] = React.useState('');
    const [deploymentStatus, setDeploymentStatus] = React.useState('');

    const handleTenantChange = (event) => {
        setTenant(event.target.value);
    }
    const handleHostnameChange = (event) => {
        setHostname(event.target.value)
    }
    const handleVpnipAdressChange = (event) => {
        setVpnipAddress(event.target.value)
    }
    const handleDeploymentStatusChange = (event) => {
        setDeploymentStatus(event.target.value)
    }
    ;
    
    var aggregator = props.rows.filter(aggregator => props.selectedRows.includes(aggregator.id))[0];
    React.useEffect(() => {
        async function getData () {
            if (account) {
                const tenants = await GetTenants(instance, account)
                setTenantList(tenants);
                setTenant(tenants[0]?.id)
            }
        }
        getData();
    }, [account, instance]);

    const handleClose = () => {
        props.handleClose();
        setTenant(tenantList[0]?.id);
        setHostname('');
        setVpnipAddress('');
        setDeploymentStatus('');
    }

    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth>
            <DialogTitle>EDIT AGGREGATORS</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={3} sx={{width:200}}>
                        <FormControl required fullWidth sx={{ mt: 1}}>
                            <InputLabel id="tenant-select-label">Tenant</InputLabel>
                            <Select
                                labelId="tenant-select-label"
                                id="tenant-select"
                                value={tenant}
                                label="Tenant"
                                onChange={handleTenantChange}
                            >
                                {
                                    tenantList.map(({id, name}) =>
                                        <MenuItem value={id}>{name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} sx={{width:200}}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="Hostname"
                            label="Hostname"
                            fullWidth
                            variant="outlined"
                            defaultValue={aggregator?.hostname}
                            onChange={handleHostnameChange}
                        />
                    </Grid>
                    <Grid item xs={3} sx={{width:200}}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="vpnipAddress"
                            label="VPN IPAddress"
                            fullWidth
                            variant="outlined"
                            defaultValue={aggregator?.vpnipAddress}
                            onChange={handleVpnipAdressChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={3} sx={{ width: "400px" }}>
                        <DeploymentStatusDropdown
                            handleChange={handleDeploymentStatusChange}
                            deploymentStatus={deploymentStatus}
                        />
                    </Grid>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center", float: "right" }}>
                        <Button
                            onClick={() => {
                                props.editData(tenant, vpnipAddress, hostname, deploymentStatus);
                                handleClose();
                            }} 
                            disabled={!tenant}
                            variant="contained"
                            sx={{ height: '80%' }}
                        >
                            CONFIRM
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default EditAggregatorDialog