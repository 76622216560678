import React from "react";
import Box from "@mui/material/Box";
import { Card } from "@mui/material";
import { useMsal, useAccount } from "@azure/msal-react";

import NotificationEmail from "./NotificationEmail";


const Settings = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  return (
    <Box sx={{ padding: "20px", paddingBottom: "0" }}>
      <Card>
        <NotificationEmail
          loginInfo={{ instance, account }}
        />
      </Card>
    </Box>
  );
};
export default Settings;
