import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Typography from "@mui/material/Typography"
import MuiPopover from "@mui/material/Popover"
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Stop, PlayArrow, Archive, Unarchive, PriorityHigh } from '@mui/icons-material';

const Popover = withStyles((theme) => ({
    root: {},
    paper: {
      backgroundColor: theme.palette.primary.main,
    },
  }))(MuiPopover)

const Modes = {
    ONLINE: "online",
    OFFLINE: "offline",
    STORE: "store",
    REPLAY: "replay"
}

const StatusWarnings = {
    1: "Mode not propagated to all modules yet",
    2: "Mode not propagated to IRF module yet",
    3: "Mode not propagated to CRF module yet"
}

const ModeCell = (props) => {
    const [mode, setMode] = useState(props.mode?.value.toLowerCase());
    const handleMode = (event, newMode) => {
        if (newMode !== null && !props.readonly) {
            props.handleMode(newMode);
            setMode(newMode);
            props.mode.value = newMode;
        }
    };
    
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    return (
      props.mode === null ? <></> :
      <ToggleButtonGroup
        value={mode}
        exclusive
        onChange={handleMode}
        aria-label="text alignment"
        sx={{alignItems: "center"}}
      >
        <ToggleButton value={Modes.OFFLINE} aria-label="Offline">
          <Stop />
        </ToggleButton>
        <ToggleButton value={Modes.ONLINE} aria-label="Online">
          <PlayArrow />
        </ToggleButton>
        <ToggleButton value={Modes.STORE} aria-label="Store Data">
          <Archive />
        </ToggleButton>
        <ToggleButton value={Modes.REPLAY} aria-label="Replay Data">
          <Unarchive />
        </ToggleButton>
        {
            props.mode.status === 0 ?
            [] :
            [
                <PriorityHigh 
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose} 
                    color='warning' 
                />,
                <Popover
                    id={popoverId}
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                >
                    <Typography sx={{ p: 1 }} color="white">
                        {StatusWarnings[props.mode.status]}
                    </Typography>
                </Popover>
            ]
        }
      </ToggleButtonGroup>
    )
}

export default ModeCell