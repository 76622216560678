import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import InventoryTable from './InventoryTable';
import { useMsal, useAccount } from "@azure/msal-react";
import { saveAs } from 'file-saver'
import GetTargets from '../../API/GetTargets';
import { fetchTargets, addTarget, deleteLocalTarget } from '../../reducer/targets';
import { useSelector, useDispatch } from 'react-redux';
import AddTargetDialog from '../Dialog/AddTargetDialog';
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import DeleteTarget from '../../API/DeleteTarget';
import GetTargetBeatsConfig from '../../API/GetTargetBeatsConfig';
import { toast } from "react-toastify";

const baseColumns = [
    {
        id: 'targetId',
        numeric: true,
        disablePadding: true,
        label: 'Target Id',
    },
    {
        id: 'palisadeId',
        numeric: false,
        disablePadding: false,
        label: 'Palisade Sensor Id',
    },
    {
        id: 'tenant',
        numeric: true,
        disablePadding: false,
        label: 'Tenant',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description',
    },
];

const baseActions = new Map([
    [
        'beats',
        {
            icon: "download",
            tooltip: "Beats Configuration",
            color: "inherit",
            onClick: () => { },
        }
    ],
    [
        'delete',
        {
            icon: "delete",
            tooltip: "Delete",
            color: "error",
            onClick: () => { },
        }
    ],
]);

const Targets = (props) => {
    const dispatch = useDispatch();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    var targetsList = useSelector(state => state.targets);
    const tenantsDict = useSelector(state => state.tenantsDict);
    const [rows, setRows] = useState([]);
    const [cols, setCols] = useState(baseColumns);
    const [actions, setActions] = useState(baseActions);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [deleteId, setDeleteId] = React.useState();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
    const [localTargets, setLocalTargets] = React.useState([]);
    const addRow = ({ targetId, palisadeId, tenantId, description }) => {
        dispatch(addTarget({ targetId, palisadeId, tenantId, description }));
    }
    const [role, setRole] = useState(account ? account.idTokenClaims?.extension_appRole?.toLowerCase() : "");

    if (role === "superadmin" || role === "admin") {
        actions.get('delete').onClick = (event, id) => {
            setDeleteId(id);
            setDeleteConfirmOpen(true);
        }
    }

    actions.get('beats').onClick = (event, {tenantId, targetId}) => {
        GetTargetBeatsConfig(instance, account, tenantId, targetId)
            .then((response) => {
                if (response.ok) {
                    response.blob().then(blob => { saveAs(blob, "BeatsConfig.zip"); })
                }
                else {
                    toast.error("Unable to download the Beats Configuration");
                }
            }, (error) => {
                toast.error("Unable to download the Beats Configuration");
            })
    }

    const deleteTarget = () => {
        DeleteTarget(instance, account, deleteId.tenantId, deleteId.targetId)
            .then((response) => {
                if (response.ok) {
                    toast.success("Target deleted successfully");
                    dispatch(deleteLocalTarget(deleteId));
                } else {
                    toast.error("Unable to delete target");
                }
            },
                (error) => { toast.error("Unable to delete target") });;
    }

    useEffect(() => {
        if (account) {
            dispatch(fetchTargets(instance, account));
        }
    }, [account, instance, dispatch]);

    useEffect(() => {
        setLocalTargets(targetsList);
    }, [targetsList])

    useEffect(() => {
        setRole(account ? account.idTokenClaims?.extension_appRole?.toLowerCase() : "");
    }, [account]);


    useEffect(() => {
        setRows(localTargets.map(
            ({ targetId, palisadeId, tenantId, description }) =>
                ({ id: {tenantId, targetId}, targetId: targetId, palisadeId: palisadeId, tenant: tenantsDict[tenantId], description })
        ));
        if (role !== "superadmin" && role !== "superuser") {
            setCols([...baseColumns.slice(0, 2), ...baseColumns.slice(3, 4)]);
        }
        if (role !== "superadmin" && role !== "admin") {
            actions.delete('delete');
            setActions(actions);
        }
    }, [account, actions, instance, localTargets, tenantsDict]);

    return (
        <>
            <InventoryTable
                rows={rows}
                columns={cols}
                actions={actions}
                selected={selectedRows}
                setSelected={setSelectedRows}
                checkbox={false}
            />
            <ConfirmationDialog
                title="DELETE TARGET"
                open={deleteConfirmOpen}
                setOpen={setDeleteConfirmOpen}
                onConfirm={deleteTarget}
            >
                Are you sure you want to delete target {deleteId?.targetId}?
            </ConfirmationDialog>
            <AddTargetDialog
                open={props.openAddDialog}
                handleClose={props.handleAddClose}
                addRow={addRow}
                protectedPalisades={new Set(localTargets.map(target => target.palisadeId))}
            />
        </>
    );
}

export default Targets;