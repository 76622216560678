export const translateNotificationEvents = (eventDetails) => {
    const translationTable =  {
        UserInvitation: {
            message: `New user invitation sent`,
            icon: 'person_add',
            iconColor: '#1A73E7',
        },
        RuleAlert: {
            message: `Alert fired from rule ${eventDetails.notifierId}`,
            icon: 'error',
            iconColor: '#E91F63',
        },
        PalisadeAdded: {
            message: `Palisade sensor ${eventDetails.notifierId} created`,
            icon: 'sensors',
            iconColor: '#4CAF50',
        },
        PalisadeDeleted: {
            message: `Palisade sensor ${eventDetails.notifierId} deleted`,
            icon: 'sensors',
            iconColor: '#AF4C4C',
        },
        PalisadeTransmissionEnabled: {
            message: `Transmission enabled for Palisade sensor ${eventDetails.notifierId}`,
            icon: 'send',
            iconColor: '#4CAF50',
        },
        PalisadeTransmissionDisabled: {
            message: `Transmission disabled for Palisade sensor ${eventDetails.notifierId}`,
            icon: 'cancel_schedule_send',
            iconColor: '#AF4C4C',
        },
        PalisadeReset: {
            message: `Palisade sensor ${eventDetails.notifierId} restarted`,
            icon: 'restart_alt',
            iconColor: '#4CAF50',
        },
        AggregatorAdded: {
            message: `Aggregator ${eventDetails.notifierId} created`,
            icon: 'device_hub',
            iconColor: '#4CAF50',
        },
        AggregatorDeleted: {
            message: `Aggregator ${eventDetails.notifierId} deleted`,
            icon: 'device_hub',
            iconColor: '#AF4C4C',
        },
        TargetAdded: {
            message: `Target ${eventDetails.notifierId} created`,
            icon: 'health_and_safety',
            iconColor: '#4CAF50',
        },
        TargetDeleted: {
            message: `Target ${eventDetails.notifierId} deleted`,
            icon: 'gpp_bad',
            iconColor: '#AF4C4C',
        },
        RuleAdded: {
            message: `Rule ${eventDetails.notifierId} created`,
            icon: 'rule',
            iconColor: '#4CAF50',
        },
        RuleDeleted: {
            message: `Rule ${eventDetails.notifierId} deleted`,
            icon: 'rule',
            iconColor: '#AF4C4C',
        },
        RuleEnabled: {
            message: `Rule ${eventDetails.notifierId} enabled`,
            icon: 'rule',
            iconColor: '#4CAF50',
        },
        RuleDisabled: {
            message: `Rule ${eventDetails.notifierId} disabled`,
            icon: 'rule',
            iconColor: '#AF4C4C',
        },
        UserDeleted: {
            message: `User ${eventDetails.userId} deleted`,
            icon: 'person_remove',
            iconColor: '#AF4C4C',
        },
        TenantAdded: {
            message: `Tenant ${eventDetails.tenantId} added`,
            icon: '',
            iconColor: '#4CAF50',
        },
        TenantDeleted: {
            message: `Tenant ${eventDetails.tenantId} deleted`,
            icon: '',
            iconColor: '#AF4C4C',
        }
    }
    return translationTable[eventDetails.type];
}