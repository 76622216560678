import * as React from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { AddCircleOutline } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { Alert } from "@mui/material";
import { toast } from "react-toastify";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PostTenant from "../../API/PostTenant";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

class AddTenantsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          name: "",
          description: "",
          password: "",
          showPassword: true,
        },
      ],
    };
  }

  handleClose = () => {
    this.props.handleClose();
    this.setState({
      data: [
        {
          name: "",
          description: "",
          password: "",
          showPassword: true,
        },
      ],
    });
  };

  handleChange = (event, row, col) => {
    this.setState((state) => {
      const newRow = state.data[row];
      newRow[col] = event.target.value;
      return {
        data: [
          ...state.data.slice(0, row),
          newRow,
          ...state.data.slice(row + 1, state.data.length),
        ],
      };
    });
  };

  toggleShowPassword = (row) => {
    this.setState((state) => {
      const newRow = state.data[row];
      newRow["showPassword"] = !newRow["showPassword"];
      return {
        data: [
          ...state.data.slice(0, row),
          newRow,
          ...state.data.slice(row + 1, state.data.length),
        ],
      };
    })
  }

  handleExtraAdd = () => {
    this.setState((state) => {
      const newData = [
        ...state.data,
        {
          name: "",
          description: "",
          password: "",
          showPassword: true,
        },
      ];
      return {
        data: newData,
      };
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  checkValidation = (newData) => {
    const { tenants } = this.props;
    let isValid = true;
    tenants.forEach((tenant) => {
      return newData.forEach((data) => {
        if (data.name.toLowerCase() === tenant.name.toLowerCase()) {
          isValid = false;
          return;
        }
      });
    });
    return isValid;
  };

  addTenants = () => {
    const { data } = this.state;
    const { loginInfo } = this.props;
    const { instance, account } = loginInfo;
    const isValid = this.checkValidation(data);
    if (isValid) {
      data.forEach(async ({ name, description, password }) => {
        await PostTenant(instance, account, {
          Id: "00000000-0000-0000-0000-000000000000",
          Name: name,
          Description: description,
          MinioPassword: password
        })
          .then((response) => {
            if (response.ok) {
              response.json()
                .then((newTenant) => {
                  this.props.addRow(newTenant);
                })
              toast.success("Successfully added tenant");
            } else {
              toast.error("Unable to add tenant");
            }
          }, (error) => {
            toast.error("Unable to add tenant");
          });
      });
      document.getElementById("error-alert").style.display = "none";
      this.handleClose();
    } else {
      document.getElementById("error-alert").style.display = "flex";
    }
  };

  render() {
    const { open } = this.props;
    const { data } = this.state;
    return (
      <Dialog open={open} onClose={() => this.handleClose()} maxWidth>
        <DialogTitle>ADD NEW TENANT</DialogTitle>
        <DialogContent>
          <Alert
            severity="error"
            id="error-alert"
            style={{ marginBottom: "15px", display: "none" }}
          >
            Tenant Name already exists!
          </Alert>
          <Grid container spacing={2} columns={16} width={1000}>
            {data.map(({ name, description, password, showPassword}, index) => (
              <>
                <Grid item xs={5}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id={`name-${index}`}
                    label="Tenant Name"
                    fullWidth
                    variant="outlined"
                    defaultValue={name}
                    onChange={(event) => {
                      this.handleChange(event, index, "name");
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    margin="dense"
                    id={`description-${index}`}
                    label="Description"
                    fullWidth
                    variant="outlined"
                    multiline
                    maxRows={4}
                    defaultValue={description}
                    onChange={(event) => {
                      this.handleChange(event, index, "description");
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                <FormControl sx={{ m: 1 }} variant="outlined">
                <InputLabel htmlFor={`description2-${index}`}>Minio Secret</InputLabel>
                  <OutlinedInput
                    margin="dense"
                    id={`description2-${index}`}
                    label="Minio Secret"
                    fullWidth
                    variant="outlined"
                    maxRows={4}
                    value={password}
                    required
                    type={showPassword ? 'password': 'text'}
                    onChange={(event) => {
                      this.handleChange(event, index, "password");
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={()=>this.toggleShowPassword(index)}
                          onMouseDown={(e)=>this.handleMouseDownPassword(e)}
                          edge="end"
                        >
                          { showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  /> 
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {index + 1 === data.length ? (
                    <IconButton onClick={() => this.handleExtraAdd()}>
                      <AddCircleOutline />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </Grid>
              </>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.addTenants()} variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenants: state.tenants,
    loginInfo: state.loginInfo,
  };
};

export default connect(mapStateToProps)(AddTenantsDialog);
