import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import MuiPopover from "@mui/material/Popover"
import Typography from "@mui/material/Typography"
import InventoryTable from './InventoryTable';
import { useMsal, useAccount } from "@azure/msal-react";
import {
    fetchPalisades,
    setPalisadeTransmission,
    setPalisadeCANTransmission
} from '../../reducer/palisades';
import { useSelector, useDispatch } from 'react-redux';
import PostPalisadeReset from '../../API/PostPalisadeReset';
import GetPalisadeInfo from '../../API/GetPalisadeInfo';
import TransmissionCell from '../Cells/TransmissionCell';
import PutTransmission from '../../API/PutTransmission';
import PutCANTransmission from '../../API/PutCANTransmission';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Popover = withStyles((theme) => ({
    root: {},
    paper: {
        backgroundColor: theme.palette.primary.main,
    },
}))(MuiPopover)

const baseColumns = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Device Id',
    },
    {
        id: 'MACAddress',
        numeric: false,
        disablePadding: true,
        label: 'MAC Address',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    }
];

const baseActions = new Map([
    [
        'info',
        {
            icon: "info",
            tooltip: "Device Info",
            color: "inherit",
            onClick: () => { },
        }
    ],
    [
        'transmission',
        {
            type: "rowAction",
            rowKey: "transmissionEnabled"
        }
    ],
    [
        'can-transmission',
        {
            type: "rowAction",
            rowKey: "canTransmissionEnabled"
        }
    ],
    [
        'reset',
        {
            icon: "restart_alt",
            tooltip: "Soft Reset",
            onClick: () => { },
        }
    ]
]);

const PalisadeSensors = (props) => {
    const dispatch = useDispatch();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const allPalisades = useSelector(state => state.palisades);
    const [palisadeMap, setPalisadeMap] = React.useState(new Map());
    const [palisadesList, setPalisadesList] = React.useState([]);
    const tenantsDict = useSelector(state => state.tenantsDict);
    const [rows, setRows] = useState([]);
    const [cols, setCols] = useState(baseColumns);
    const [actions, setActions] = useState(baseActions);
    const [selectedRows, setSelectedRows] = React.useState([]); // We need these here for editing
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [infoAnchorEl, setInfoAnchorEl] = React.useState(null);
    const [deviceInfo, setDeviceInfo] = React.useState(null);


    if (actions.get('info')) {
        actions.get('info').onClick = (event, id) => {
            GetPalisadeInfo(instance, account, id)
                .then(response => {
                    setDeviceInfo(response);
                }, (error) => {
                    toast.error("Device Info could not be retrieved");
                });

            setInfoAnchorEl(event.currentTarget);
        }
    }

    if (actions.get('reset')) {
        actions.get('reset').onClick = (event, id) => {
            const toastResetLoading = toast.loading(
                "Reset sent to palisade sensor " + id,
                {
                    toastId: id
                }
            );
            PostPalisadeReset(instance, account, id).then(
                (response) => { },
                (error) => {
                    toast.dismiss(toastResetLoading);
                    toast.error("Failed to reset palisade");
                }
            );
        }
    }



    const handleInfoPopoverClose = () => {
        setInfoAnchorEl(null);
        setDeviceInfo(null);
    };
    const infoPopoverOpen = Boolean(infoAnchorEl);
    const infoPopoverId = infoPopoverOpen ? 'infoPopover' : undefined;



    useEffect(() => {
        if (account) {
            dispatch(fetchPalisades(instance, account));
        }
    }, [account, instance, dispatch]);

    useEffect(() => {
        if (props.superAccount) {
            setPalisadeMap(new Map(
                allPalisades.reduce(
                    (mapSoFar, palisade) => {
                        mapSoFar.set(palisade.tenantId, (mapSoFar.get(palisade.tenantId) || []));
                        mapSoFar.get(palisade.tenantId).push(palisade);
                        return mapSoFar;
                    }, new Map()
                )
            ));
        }
    }, [allPalisades, props.superAccount])

    useEffect(() => {
        setPalisadesList(props.superAccount ? palisadeMap.get(props.tenant) : allPalisades);
    }, [allPalisades, palisadeMap, props.superAccount, props.tenant]);

    useEffect(() => {
        const role = account.idTokenClaims?.extension_appRole?.toLowerCase();
        setRows(palisadesList?.map(
            ({ id, tenantId, mcuSerial, mcuModel, hwVersion, connected, transmissionEnabled, canTransmissionEnabled, macAddress }) => {

                return ({
                    id,
                    status: connected,
                    MACAddress: macAddress,
                    transmissionEnabled: <TransmissionCell
                        transmissionEnabled={transmissionEnabled}
                        readonly={!(role === "superadmin" || role === "admin")}
                        handleTransmission={async (newTransmission) => {
                            var transmissionString = newTransmission ? "enabled" : "disabled";
                            await PutTransmission(instance, account, id, newTransmission).then((response) => {
                                if (response.ok) {
                                    toast.success("Transmission is now " + transmissionString);
                                    dispatch(setPalisadeTransmission({ id, newTransmission }));
                                } else {
                                    // bad response
                                    toast.error("Transmission toggle failed");
                                }
                            }, (error) => {
                                // network error
                                toast.error("Transmission toggle failed");
                            });
                        }}
                    />,
                    canTransmissionEnabled: <TransmissionCell
                        can={true}
                        transmissionEnabled={canTransmissionEnabled}
                        readonly={!(role === "superadmin" || role === "admin")}
                        handleTransmission={async (newTransmission) => {
                            var transmissionString = newTransmission ? "enabled" : "disabled";
                            await PutCANTransmission(instance, account, id, newTransmission).then((response) => {
                                if (response.ok) {
                                    toast.success("CAN Transmission is now " + transmissionString);
                                    dispatch(setPalisadeCANTransmission({ id, newTransmission }));
                                } else {
                                    // bad response
                                    toast.error("CAN Transmission toggle failed");
                                }
                            }, (error) => {
                                // network error
                                toast.error("CAN Transmission toggle failed");
                            });
                        }}
                    />
                })
            }
        ) || []);

        if (role !== "superadmin" && role !== "superuser") {
            setCols([...baseColumns.slice(0, 1), ...baseColumns.slice(2, 5)]);
        }
        if (role !== "admin" && role !== "superadmin") {
            actions.delete('reset');
        }
        if (role !== "superadmin") {
            actions.delete('info');
            setActions(actions);
        }
    }, [account, instance, palisadesList, tenantsDict])

    useEffect(() => {
        props.setAnySelected(selectedRows.length)
    }, [selectedRows])

    // Enable Selected Transmission clicked
    useEffect(() => {
        if (props.enableSelectedTransmission) {
            rows.forEach(async ({ id }) => {
                if (selectedRows.includes(id)) {
                    const response = await PutTransmission(instance, account, id, true);
                    if (response.ok) {
                        toast.success("Transmission is now enabled");
                        dispatch(setPalisadeTransmission({ id, newTransmission: true }));
                    } else {
                        toast.error("Transmission enable failed");
                    }
                }
            })
            props.finishEnableSelectedTransmission()
        }
    }, [selectedRows, props.enableSelectedTransmission, props.finishEnableSelectedTransmission])

    // Enable Selected CAN Transmission clicked
    useEffect(() => {
        if (props.enableSelectedCANTransmission) {
            rows.forEach(async ({ id }) => {
                if (selectedRows.includes(id)) {
                    const response = await PutCANTransmission(instance, account, id, true);
                    if (response.ok) {
                        toast.success("CAN Transmission is now enabled");
                        dispatch(setPalisadeCANTransmission({ id, newTransmission: true }));
                    } else {
                        toast.error("CAN Transmission enable failed");
                    }
                }
            })
            props.finishEnableSelectedCANTransmission()
        }
    }, [selectedRows, props.enableSelectedTransmission, props.finishEnableSelectedTransmission])

    useEffect(() => {
        if (!props.anySelected) {
            setSelectedRows([]);
        }
    }, [props.anySelected])


    return (
        <>
            <InventoryTable
                rows={rows}
                columns={cols}
                actions={actions}
                selected={selectedRows}
                setSelected={setSelectedRows}
                Sensors={true}
            />

            <Popover
                id={infoPopoverId}
                open={infoPopoverOpen}
                anchorEl={infoAnchorEl}
                onClose={handleInfoPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Typography sx={{ p: 1 }} color="white">
                    {`Connection Reset Count: ${deviceInfo?.connectionResetCount ?? 'N/A'}`}
                </Typography>
                <Typography sx={{ p: 1 }} color="white">
                    {`Restart Count: ${deviceInfo?.restartCount ?? 'N/A'}`}
                </Typography>
                <Typography sx={{ p: 1 }} color="white">
                    {`Last Up Time: ${deviceInfo?.lastUpTime ?? 'N/A'}`}
                </Typography>
            </Popover>
        </>
    );
}

export default PalisadeSensors;