import { createSlice } from '@reduxjs/toolkit'

export const loginInfoSlice = createSlice({
  name: 'loginInfo',
  initialState: [],
  reducers: {
    setLoginInfo: (state, action) => action.payload,
  }
})


export const { setLoginInfo } = loginInfoSlice.actions;

export const loginInfoReducer =  loginInfoSlice.reducer;