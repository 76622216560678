import React, { useState, useEffect } from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import GetRules from '../../API/GetRules';
import { Paper, Box, Button, Grid } from '@mui/material';
import RulesTable from '../Tables/RulesTable';
import PutRule from '../../API/PutRule';
import { toast, ToastContainer } from "react-toastify";

const Rules = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    var role = "";
    if (account) {
        role = account.idTokenClaims?.extension_appRole?.toLowerCase();
    }
    const editsAuthorized = ((role === "admin") || (role === "superadmin"));
    const [rules, setRules] = useState(new Map());
    const [changedRules, setChangedRules] = useState(new Map());
    const [storedRules, setStoredRules] = useState(new Map());

    // number of input errors: used for determining whether a save is allowed or not
    const [numInputErrors, setNumInputErrors] = useState(0);

    function addChangedRule(rule) {
        const ruleId = rule.id;
        const storedVersion = storedRules.get(ruleId);
        // ruleChanged holds whether the supposed changed rule is different than the original
        //  (only need to check enabled and parameters as only these can change)
        const ruleChanged = (rule.enabled !== storedVersion.enabled ||
            rule.parameters !== storedVersion.parameters);
        var newRuleMap = new Map(rules.set(rule.id, rule));
        setRules(newRuleMap);
        if (ruleChanged) {
            setChangedRules(new Map(changedRules.set(rule.id, rule)));
        } else {
            // delete from changed rules as the rule is the same as original 
            //  (but may already be in the changedRules)
            var newChangedRules = new Map(changedRules);
            newChangedRules.delete(ruleId);
            setChangedRules(newChangedRules);
        }
    }

    // changes the number of input errors by 1 ; +1 if add is true, else -1
    function changeNumInputErrors(add) {
        setNumInputErrors(add ? numInputErrors + 1 : numInputErrors - 1);
    }

    const cancelChanges = () => {
        setChangedRules(new Map());
        const originalRuleMap = new Map(JSON.parse(JSON.stringify([...storedRules])));
        setRules(new Map(originalRuleMap));
        setNumInputErrors(0);
    }

    async function saveChanges() {
        var updatedChangedRules = new Map();
        Promise.all(
            [...changedRules.values()].map(async rule => {
                const ruleId = rule.id;
                var modelledRule = {};
                modelledRule["tenant-id"] = rule.tenantid;
                modelledRule["rule-id"] = ruleId;
                modelledRule["rule-name"] = rule.name;
                modelledRule["type"] = rule.type;
                modelledRule["parameters"] = JSON.parse(rule.parameters);
                modelledRule["notify-interval"] = rule.notifyinterval;
                modelledRule["Enabled"] = rule.enabled;

                PutRule(instance, account, modelledRule)
                    .then(
                        response => {
                            if (response.ok) {
                                toast.success("Successfully updated rule");
                                setStoredRules(new Map(JSON.parse(JSON.stringify([...storedRules.set(ruleId, rule)]))));
                            } else {
                                toast.error("Unable to update rule");
                                updatedChangedRules.set(ruleId, rule);
                            }
                        },
                        (error) => {
                            toast.error("Unable to update rule");
                            updatedChangedRules.set(ruleId, rule);
                        });
            })
        )
        setChangedRules(updatedChangedRules);
    }

    useEffect(() => {
        async function getData() {
            if (account) {
                const retrievedRules = await GetRules(instance, account);
                const ruleMap = new Map(retrievedRules.map(rule => { return [rule.id, rule]; }
                ));

                setRules(new Map(retrievedRules.map(rule => { return [rule.id, rule] })));
                // this will make a deep copy as to not interfere with "rules":
                setStoredRules(new Map(JSON.parse(JSON.stringify([...ruleMap]))));
            }
        }
        getData();
    }, [account, instance]);

    return (
        <Box sx={{ padding: "20px", paddingBottom: "0" }}>
            <Paper>
                <RulesTable
                    rules={rules}
                    editsAuthorized={editsAuthorized}
                    changeNumInputErrors={changeNumInputErrors}
                    addChangedRule={addChangedRule}
                />
            </Paper>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
            >
                <Button
                    variant="outlined"
                    size="medium"
                    style={{ margin: "15px" }}
                    disabled={changedRules.size === 0}
                    onClick={cancelChanges}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    size="medium"
                    style={{ margin: "15px" }}
                    disabled={changedRules.size === 0 || numInputErrors > 0}
                    onClick={saveChanges}>
                    Save
                </Button>
            </Grid>
            <ToastContainer />
        </Box>

    );
}

export default Rules;