import AcquireToken from "./AcquireToken";
import { PALISADE_INVENTORY_SUMMARY_API } from "./Endpoints";

async function GetPalisadeInventorySummary(instance, account) {
    const accessToken = await AcquireToken(instance, account);
    if (accessToken) {
        let response = await fetch(
            PALISADE_INVENTORY_SUMMARY_API,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            }
        );
        if (response.ok) {
            return response.json();
        } else {
            return {};
        }
    } else {
        return {};
    }
}

export default GetPalisadeInventorySummary;