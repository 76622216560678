import AcquireToken from "./AcquireToken";
import { NOTIFICATION_EVENTS_API } from "./Endpoints";

async function GetNotificationEvents(instance, account) {
  const accessToken = await AcquireToken(instance, account);
  if (accessToken) {
    return (
      await fetch(NOTIFICATION_EVENTS_API, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    );
  } else {
    return [];
  }
}

export default GetNotificationEvents;
