import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@mui/styles';
import MuiPopover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useMsal, useAccount } from "@azure/msal-react";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import InventoryTable from './InventoryTable';
import {
    fetchPalisades,
    addPalisade,
    editPalisade,
    deletePalisade,
} from '../../reducer/palisades';

import AddPalisadeDialog from "../Dialog/AddPalisadeDialog";
import EditPalisadeDialog from "../Dialog/EditPalisadeDialog";
import PutPalisade from "../../API/PutPalisade"
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import DeletePalisade from '../../API/DeletePalisade';
import GetPalisadeConnectionString from '../../API/GetPalisadeConnectionString';
import { powerConnectorToElement } from '../Utilities/enumConversions';

import './PalisadeInventory.css';


const Popover = withStyles((theme) => ({
    root: {},
    paper: {
        backgroundColor: theme.palette.primary.main,
    },
}))(MuiPopover)

const baseColumns = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Device Id',
    },
    {
        id: 'serialNo',
        numeric: false,
        disablePadding: false,
        label: 'Serial No.',
    },
    {
        id: 'MACAddress',
        numeric: false,
        disablePadding: true,
        label: 'MAC Address',
    },
    {
        id: 'chip',
        numeric: false,
        disablePadding: false,
        label: 'Chip',
    },
    {
        id: 'interfaces',
        numeric: false,
        disablePadding: true,
        label: 'Interfaces',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
];

const baseActions = new Map([
    [
        'key',
        {
            icon: "vpn_key",
            tooltip: "Connection String",
            color: "inherit",
            onClick: () => { },
        }
    ],
    [
        'delete',
        {
            icon: "delete",
            tooltip: "Delete",
            color: "error",
            onClick: () => { },
        }
    ]
]);

const PalisadeInventory = (props) => {
    const dispatch = useDispatch();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const allPalisades = useSelector(state => state.palisades);
    const [palisadeMap, setPalisadeMap] = React.useState(new Map());
    const [palisadesList, setPalisadesList] = React.useState([]);
    const tenantsDict = useSelector(state => state.tenantsDict);
    const [rows, setRows] = useState([]);
    const [cols, setCols] = useState(baseColumns);
    const [actions, setActions] = useState(baseActions);
    const [selectedRows, setSelectedRows] = React.useState([]); // We need these here for editing
    const [deletePalisadeId, setDeletePalisadeId] = React.useState();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [connectionString, setConnectionString] = React.useState("");

    const addRow = ({ id, tenantId, mcuSerial, mcuModel, hwVersion, aggregatorId, macAddress, chip, canInterface, powerConnector, deploymentStatus }) => {
        // realtime status updates aren't required so we assume devices have not been connected
        dispatch(addPalisade({ id, tenantId, mcuSerial, mcuModel, hwVersion, aggregatorId, macAddress, chip, canInterface, powerConnector, deploymentStatus }));
    }

    if (actions.get('delete')) {
        actions.get('delete').onClick = (event, id) => {
            setDeletePalisadeId(id);
            setDeleteConfirmOpen(true);
        }
    }

    if (actions.get('key')) {
        actions.get('key').onClick = (event, id) => {
            GetPalisadeConnectionString(instance, account, id)
                .then(response => {
                    if(response.connectionString !=='') {
                        setConnectionString(response.connectionString);
                    }
                    else{
                        toast.warning("Connection string is empty"); 
                    }
                }, (error) => {
                    toast.error("Connection String could not be retrieved");
                });

            setAnchorEl(event.currentTarget);
        }
    }

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setConnectionString("");
    };
    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;


    const handleDeletePalisadeAction = () => {
        DeletePalisade(instance, account, deletePalisadeId)
            .then((response) => {
                if (response.ok) {
                    toast.success("Palisade deleted successfully");
                    dispatch(deletePalisade({ deletePalisadeId }));
                } else {
                    toast.error("Unable to delete palisade");
                }
            },
                (error) => { toast.error("Unable to delete palisade") });
    }

    useEffect(() => {
        if (account) {
            dispatch(fetchPalisades(instance, account));
        }
    }, [account, instance, dispatch]);

    useEffect(() => {
        if (props.superAccount) {
            setPalisadeMap(new Map(
                allPalisades.reduce(
                    (mapSoFar, palisade) => {
                        mapSoFar.set(palisade.tenantId, (mapSoFar.get(palisade.tenantId) || []));
                        mapSoFar.get(palisade.tenantId).push(palisade);
                        return mapSoFar;
                    }, new Map()
                )
            ));
        }
    }, [allPalisades, props.superAccount])

    useEffect(() => {
        setPalisadesList(props.superAccount ? palisadeMap.get(props.tenant) : allPalisades);
    }, [allPalisades, palisadeMap, props.superAccount, props.tenant]);

    useEffect(() => {
        const role = account.idTokenClaims?.extension_appRole?.toLowerCase();
        setRows(palisadesList?.map(
            ({ id, tenantId, mcuSerial, mcuModel, hwVersion, connected, transmissionEnabled, canTransmissionEnabled, macAddress, chip, powerConnector, deploymentStatus, canInterface }) => {
                var powerConnectorInfo = powerConnectorToElement(powerConnector);
                return ({
                    id,
                    serialNo: mcuSerial,
                    status: deploymentStatus,
                    MACAddress: macAddress,
                    chip: chip,
                    interfaces:
                        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2} sx={{ width: '60%' }} className="interfaces-container">
                            <Tooltip title={powerConnectorInfo[0]}>
                                {powerConnectorInfo[1]}
                            </Tooltip>
                            {
                                canInterface &&
                                <Tooltip title={"CAN Interface"}>
                                    < Chip label="CAN" variant="outlined" size="small" className="can-chip" />
                                </Tooltip>
                            }
                        </Stack >
                    ,
                })
            }
        ) || []);

        if (role !== "superadmin" && role !== "superuser") {
            setCols([...baseColumns.slice(0, 1), ...baseColumns.slice(2, 5)]);
        }

        if (role !== "superadmin") {
            actions.delete('delete');
            actions.delete('key');
            setActions(actions);
        }
    }, [account, instance, palisadesList, tenantsDict])

    useEffect(() => {
        props.setAnySelected(selectedRows.length)
    }, [selectedRows])

    useEffect(() => {
        if (!props.anySelected) {
            setSelectedRows([]);
        }
    }, [props.anySelected])



    const editData = async (newTenant, newAggregatorId, newDeploymentStatus) => {
        rows.forEach(async ({ id, tenant }, index) => {
            if (selectedRows.includes(id)) {
                var palisade = JSON.parse(JSON.stringify(palisadesList.find(pali => pali.id === id))); // deep copy
                palisade.tenantId = newTenant;
                palisade.aggregatorId = newAggregatorId;
                palisade.deploymentStatus = newDeploymentStatus;
                const response = await PutPalisade(instance, account, palisade);
                if (response.ok) {
                    toast.success("Palisade updated successfully");
                    dispatch(editPalisade({ id, newTenant, newAggregatorId, newDeploymentStatus }));
                } else {
                    toast.error("Unable to update palisade");
                }
            }
        }
        )
        props.handleEditClose()
    }

    return (
        <>
            <InventoryTable
                rows={rows}
                columns={cols}
                actions={actions}
                selected={selectedRows}
                setSelected={setSelectedRows}
                Sensors={false}
            />
            <ConfirmationDialog
                title="DELETE PALISADE SENSOR"
                open={deleteConfirmOpen}
                setOpen={setDeleteConfirmOpen}
                onConfirm={handleDeletePalisadeAction}
            >
                Are you sure you want to delete Palisade sensor {deletePalisadeId}?
            </ConfirmationDialog>
            {connectionString && (
                <Popover
                    id={popoverId}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Typography sx={{ p: 1 }} color="white">{connectionString}
                        <span style={{padding: '10px'}}>
                            <CopyToClipboard text={connectionString} onCopy={()=>{toast.success("Connection String Copied")}}>
                                <IconButton aria-label="copy" sx={{color: "white"}}>
                                    <ContentCopyIcon />
                                </IconButton>
                            </CopyToClipboard>
                        </span>
                    </Typography>

                </Popover>
            )}
            <AddPalisadeDialog
                open={props.openAddDialog}
                handleClose={props.handleAddClose}
                addRow={addRow}
                tenant={props.tenant}
            />
            <EditPalisadeDialog
                open={props.openEditDialog}
                handleClose={props.handleEditClose}
                editData={editData}
                tenant={props.tenant}
            />
        </>
    );
}

export default PalisadeInventory;