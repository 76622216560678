import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import InventoryTable from './InventoryTable';
import { useMsal, useAccount } from "@azure/msal-react";
import GetAggregators from "../../API/GetAggregators";
import PutAggregator from "../../API/PutAggregator";
import ModeCell from "../Cells/ModeCell";
import { toast } from "react-toastify";
import { inventoryTenantId } from "../Utilities/api";

const baseColumns = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Device Id',
    },
    {
        id: 'tenant',
        numeric: true,
        disablePadding: false,
        label: 'Tenant',
    },
    {
        id: 'macAddress',
        numeric: false,
        disablePadding: false,
        label: 'Mac Address',
    },
    {
        id: 'hostname',
        numeric: false,
        disablePadding: false,
        label: 'Hostname',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'mode',
        numeric: false,
        disablePadding: false,
        label: 'Mode',
    },
];


const Aggregators = (props) => {
    const { tenantsDict } = props;
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [rows, setRows] = useState([]);
    const [cols, setCols] = useState(baseColumns);
    const [selectedRows, setSelectedRows] = React.useState([]); // We need these here for editing

    useEffect(() => {
        async function getData() {
            if (account) {
                const aggregators = await GetAggregators(instance, account);
                const role = account.idTokenClaims?.extension_appRole?.toLowerCase();
                setRows(
                    aggregators
                        .filter(
                            ({ tenantId }) => {
                                return tenantId !== inventoryTenantId;
                            }
                        )
                        .map((agg) => {
                            let  { id, tenantId, macAddress, hostname, connected, mode } = agg;
                            return {
                                id,
                                tenant: tenantsDict[tenantId],
                                macAddress,
                                hostname,
                                status: connected,
                                mode: (
                                    <ModeCell
                                        mode={mode}
                                        handleMode={async (newMode) => {
                                            await PutAggregator(
                                                instance,
                                                account,
                                                { 
                                                    ...agg,
                                                    mode: {...mode, value: newMode}
                                                }
                                            ).then(
                                                (response) => {
                                                    if (response.ok) {
                                                        toast.success("Successfully changed mode");
                                                    } else {
                                                        toast.error("Unable to change mode");
                                                    }
                                                },
                                                (error) => {
                                                    toast.error("Unable to change mode");
                                                }
                                            );
                                        }}
                                        readonly={role !== "superadmin"}
                                    />
                                ),
                            };
                        }) || []
                );
                if (role !== "superadmin" && role !== "superuser") {
                    setCols([...baseColumns.slice(0, 1), ...baseColumns.slice(2, 3)]);
                }
            }
        }
        getData();
    }, [account, instance]);

    useEffect(() => {
        props.setAnySelected(selectedRows.length)
    }, [selectedRows])

    return (
        <>
            <InventoryTable
                rows={rows}
                columns={cols}
                selected={selectedRows}
                checkbox={false}
                setSelected={setSelectedRows}
                Sensors={true} />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        tenantsDict: state.tenantsDict
    }
}

export default connect(mapStateToProps)(Aggregators);