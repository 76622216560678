import { createSlice } from "@reduxjs/toolkit";

export const ruleAlertTypesSlice = createSlice({
  name: "ruleAlertTypes",
  initialState: [],
  reducers: {
    setRuleAlertTypes: (state, action) => {
        return action.payload
    },
  },
});

export const { setRuleAlertTypes } = ruleAlertTypesSlice.actions;

export const ruleAlertTypesReducer = ruleAlertTypesSlice.reducer;

export const rulesAlertSlice = createSlice({
  name: "palisadeStats",
  initialState: [],
  reducers: {
    setRulesAlert: (state, action) => action.payload,
  },
});

export const { setRulesAlert } = rulesAlertSlice.actions;

export const rulesAlertReducer = rulesAlertSlice.reducer;
