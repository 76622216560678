import GetTargets from "../API/GetTargets"
import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const targetsInfoSlice = createSlice({
    name: 'targetsInfo',
    initialState: [],
    reducers: {
        setTargets: (state, action) => action.payload,
        addTarget: (state, action) => {
            state.push(action.payload);
        },
        deleteLocalTarget: (state, action) => {
            const deleteId = action.payload;
            const newState = state.filter(target => 
                !(target.tenantId == deleteId.tenantId && target.targetId == deleteId.targetId)
              );
            return newState;
        },
    }
});

export const { setTargets, addTarget, deleteLocalTarget } = targetsInfoSlice.actions;

export const targetsInfoReducer = targetsInfoSlice.reducer;

export function fetchTargets(instance, account) {
    return async (dispatch, getState) => {
        const response = await GetTargets(instance, account);
        dispatch(setTargets(response));
    }
}
