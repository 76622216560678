import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";

import "./SideMenu.css";

class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.tenantId = "";
    this.tenantsDict = "";
    this.displayTab = false;
    const pathname = window.location.pathname;
    this.defaultTabName = "Dashboard";
    this.state = {
      selectedTabKey:
        pathname === "/" ? this.defaultTabName : pathname.substring(1),
    };
  }

  render() {
    const { tab2, tab3, tab4, tab5, tab6, tab7 } = this.props;
    const { selectedTabKey } = this.state;
    if (this.props.loginInfo !== null) {
      this.tenantId = JSON.parse(this.props.loginInfo).extension_appTenantId;
      this.tenantsDict = this.props.tenantsDict;
      const userRole = JSON.parse(
        this.props.loginInfo
      ).extension_appRole.toLowerCase();
      this.displayTab = userRole === "superadmin" || userRole === "superuser";
    }

    return (
      <div className="side-menu-container">
        <Divider className="divider-sidemenu" />
        <Box sx={{ fontWeight: "medium", m: 1, fontSize: 20 }}>
          {this.tenantsDict[this.tenantId]}
        </Box>

        <Divider className="divider-sidemenu" />

        <List>
          <Link to="/">
            <ListItem
              button
              key={this.defaultTabName}
              className={`menu-button ${selectedTabKey === this.defaultTabName ? "selected" : ""
                }`}
              onClick={() => {
                this.setState({ selectedTabKey: this.defaultTabName });
              }}
            >
              <ListItemIcon>
                <span className="material-icons">dashboard</span>
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>

          <Link to={`/${tab2}`}>
            <ListItem
              button
              key={tab2}
              className={`menu-button ${selectedTabKey === tab2 ? "selected" : ""
                }`}
              onClick={() => {
                this.setState({ selectedTabKey: tab2 });
              }}
            >
              <ListItemIcon>
                <span className="material-icons">security</span>
              </ListItemIcon>
              <ListItemText primary="Protected Assets" />
            </ListItem>
          </Link>

          <Link to={`/${tab3}`}>
            <ListItem
              button
              key={tab3}
              className={`menu-button ${selectedTabKey === tab3 ? "selected" : ""
                }`}
              onClick={() => {
                this.setState({ selectedTabKey: tab3 });
              }}
            >
              <ListItemIcon>
                <span className="material-icons">rule</span>
              </ListItemIcon>
              <ListItemText primary="Rules" />
            </ListItem>
          </Link>

          <Link to={`/${tab4}`}>
            <ListItem
              button
              key={tab4}
              className={`menu-button ${selectedTabKey === tab4 ? "selected" : ""
                }`}
              onClick={() => {
                this.setState({ selectedTabKey: tab4 });
              }}
            >
              <ListItemIcon>
                <span className="material-icons">sensors</span>
              </ListItemIcon>
              <ListItemText primary="Sensors" />
            </ListItem>
          </Link>
          {this.displayTab && (
            <Link to={`/${tab5}`}>
              <ListItem
                button
                key={tab5}
                className={`menu-button ${selectedTabKey === tab5 ? "selected" : ""
                  }`}
                onClick={() => {
                  this.setState({ selectedTabKey: tab5 });
                }}
              >
                <ListItemIcon>
                  <span className="material-icons">inventory</span>
                </ListItemIcon>
                <ListItemText primary="Inventory" />
              </ListItem>
            </Link>
          )
          }

          {this.displayTab && (
            <Link to={`/${tab6}`}>
              <ListItem
                button
                key={tab6}
                className={`menu-button ${selectedTabKey === tab6 ? "selected" : ""
                  }`}
                onClick={() => {
                  this.setState({ selectedTabKey: tab6 });
                }}
              >
                <ListItemIcon>
                  <span className="material-icons">people</span>
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>
            </Link>
          )}
        </List>

        <Divider className="divider-sidemenu" />

        <List className="bottom-list">
          <Link to={`/${tab7}`}>
            <ListItem
              button
              key={tab7}
              className={`menu-button ${selectedTabKey === tab7 ? "selected" : ""
                }`}
              onClick={() => {
                this.setState({ selectedTabKey: tab7 });
              }}
            >
              <ListItemIcon>
                <span className="material-icons">settings</span>
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
          </Link>

          <a
            href="https://www.palitronica.com/"
            target="_blank"
            rel="noreferrer"
          >
            <ListItem button key="Official Page" className="menu-button">
              <ListItemIcon>
                <span className="material-icons">home</span>
              </ListItemIcon>
              <ListItemText primary="Official Page" />
            </ListItem>
          </a>

          <a
            href="https://www.palitronica.com/company/contact"
            target="_blank"
            rel="noreferrer"
          >
            <ListItem button key="Contact" className="menu-button">
              <ListItemIcon>
                <span className="material-icons">alternate_email</span>
              </ListItemIcon>
              <ListItemText primary="Contact" />
            </ListItem>
          </a>
        </List>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenantsDict: state.tenantsDict,
  };
};

export default connect(mapStateToProps)(SideMenu);
