const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}`; // Should probably be in an env file?
const DEVICE_MANAGEMENT_API = `https://${API_ENDPOINT}/device-management`;
const USER_MANAGEMENT_API = `https://${API_ENDPOINT}/user-management`
const CONFIGURATION_API = `https://${API_ENDPOINT}/configuration`
const RULE_ENGINE_API = `https://${API_ENDPOINT}/rule-engine`;
const NOTIFICATION_API = `https://${API_ENDPOINT}/notification`;
const SIGNALR_ENDPOINT = `https://${process.env.REACT_APP_SIGNALR_ENDPOINT}`;

export const PALISADE_API = `${DEVICE_MANAGEMENT_API}/api/palisade`;
export const PALISADE_STATS_API = `${DEVICE_MANAGEMENT_API}/api/palisade/stats`;
export const PALISADE_INVENTORY_SUMMARY_API = `${DEVICE_MANAGEMENT_API}/api/palisade/inventory-summary`;
export const AGGREGATOR_API = `${DEVICE_MANAGEMENT_API}/api/aggregator`;
export const AGGREGATOR_STATS_API = `${DEVICE_MANAGEMENT_API}/api/aggregator/stats`;
export const TENANT_API = `${USER_MANAGEMENT_API}/api/tenant`;
export const USER_API = `${USER_MANAGEMENT_API}/api/user`;
const INVITATION_API = `${USER_MANAGEMENT_API}/api/invitation`;
export const SEND_INVITATION_API = `${INVITATION_API}/send-invite`;
export const REDEEM_INVITATION_API = `${INVITATION_API}/redeem-invite`;
export const TARGET_API = `${DEVICE_MANAGEMENT_API}/api/target`;
export const TARGET_BEATS_API = `${CONFIGURATION_API}/api/target`;
export const NOTIFICATION_SETTING = `${CONFIGURATION_API}/api/settings`;
export const RULES_API = `${RULE_ENGINE_API}/api/rule`;
export const RULE_TYPES_API = `${NOTIFICATION_API}/api/notification/rule-types`;
export const RULE_ALERTS_API = `${NOTIFICATION_API}/api/notification/rules`;
export const NOTIFICATION_SIGNALR_HUB = `${SIGNALR_ENDPOINT}/notification-hub`;
export const NOTIFICATION_EVENTS_API = `${NOTIFICATION_API}/api/notification`;
export const ENERGY_API = `${NOTIFICATION_API}/api/energy`;