import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { AddCircleOutline } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useMsal, useAccount } from "@azure/msal-react";
import GetTenants from '../../API/GetTenants';
import PostPalisade from '../../API/PostPalisades';
import GetAggregators from '../../API/GetAggregators';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { isAPIError } from '../Utilities/api';
import DeploymentStatusDropdown from '../ListItems/DeploymentStatusDropdown';
import { inventoryTenantId, noAggregator } from '../Utilities/api';
import Divider from '@mui/material/Divider';

const AddPalisadeDialog = (props) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [tenantList, setTenantList] = React.useState([]);

    // maps aggregators to their Tenant Id
    const [aggregatorMap, setAggregatorMap] = React.useState(new Map());

    React.useEffect(() => {
        async function getData() {
            if (account) {
                let tenants = await GetTenants(instance, account)
                if (isAPIError(tenants)) {
                    tenants = [];
                }
                setTenantList(tenants);
                let aggregators = await GetAggregators(instance, account);
                if (isAPIError(aggregators)) {
                    aggregators = [];
                }
                const newMap = new Map(
                    aggregators.reduce(
                        (mapSoFar, aggregator) => {
                            mapSoFar.set(aggregator.tenantId, (mapSoFar.get(aggregator.tenantId) || []));
                            mapSoFar.get(aggregator.tenantId).push(aggregator.id);
                            return mapSoFar;
                        }, new Map()
                    )
                );

                setAggregatorMap(newMap);
                setData(data =>
                    data.map(datum =>
                    ({
                        ...datum,
                        tenant: tenants.includes(datum.tenant) ? datum.tenant : props.tenant,
                        aggregator: aggregators.includes(datum.aggregator) ? datum.aggregator : newMap.get(props.tenant)?.[0],
                    })
                    )
                )
            }
        }
        getData();
    }, [account, instance, props.tenant]);

    const [data, setData] = React.useState([
        {
            palisade: '',
            tenant: props.tenant,
            aggregator: aggregatorMap?.get(props.tenant)?.[0] || '',
            serialNo: '',
            modelNo: '',
            hwVersion: '',
            macAddress: '',
            chip: '',
            canInterface: '',
            powerConnector: '',
            deploymentStatus: ''
        }
    ]);

    const handleClose = () => {
        let tenantId = props.tenant;
        props.handleClose();
        setData([
            {
                palisade: '',
                tenant: tenantId,
                aggregator: aggregatorMap?.get(tenantId)?.[0] || '',
                serialNo: '',
                modelNo: '',
                hwVersion: '',
                macAddress: '',
                chip: '',
                canInterface: '',
                powerConnector: '',
                deploymentStatus: ''
            }
        ]);
    }

    const handleChange = (event, row, col) => {
        setData(data => {
            const newRow = data[row];
            newRow[col] = event.target.value;
            return [
                ...data.slice(0, row),
                newRow,
                ...data.slice(row + 1, data.length)
            ];
        });
    }

    const handleExtraAdd = () => {
        let tenantId = props.tenant;
        setData(data => [
            ...data,
            {
                palisade: '',
                tenant: tenantId,
                aggregator: aggregatorMap?.get(tenantId)?.[0] || '',
                serialNo: '',
                modelNo: '',
                hwVersion: '',
                MACAddress: '',
                Chip: '',
                CANInterface: '',
                PowerConnector: '',
                DeploymentStatus: ''
            }
        ]);
    }

    const addPalisades = () => {
        data.forEach(async ({ palisade, tenant, aggregator, serialNo, modelNo, hwVersion, macAddress, chip, canInterface, powerConnector, deploymentStatus }) => {
            const response = await PostPalisade(instance, account,
                {
                    Id: palisade,
                    TenantId: tenant,
                    MCUSerial: serialNo,
                    MCUModel: modelNo,
                    HWVersion: hwVersion,
                    AggregatorId: aggregator === noAggregator ? null : aggregator,
                    MACAddress: macAddress,
                    Chip: chip,
                    CANInterface: canInterface,
                    PowerConnector: powerConnector,
                    DeploymentStatus: deploymentStatus
                }

            ).catch((error) => { toast.error("Unable to add palisade"); });
            if (response.ok) {
                const newPalisade = await response.json();
                props.addRow(newPalisade);
                toast.success("Palisade Successfully added");
            } else {
                toast.error("Unable to add palisade");
            }
        }
        )
        handleClose();
    }

    async function tenantChange(event, row, col) {
        let tenantId = event.target.value;
        setData(data => {
            const newRow = data[row];
            newRow.aggregator = aggregatorMap?.get(tenantId)?.[0] || '';
            newRow[col] = event.target.value;

            return [
                ...data.slice(0, row),
                newRow,
                ...data.slice(row + 1, data.length)
            ];
        });
    }

    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth>
            <DialogTitle>ADD NEW PALISADE SENSOR</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ overflowX: "auto" }}>
                    {
                        data.map(({ palisade, tenant, aggregator, serialNo, modelNo, hwVersion, macAddress, chip, canInterface, powerConnector, deploymentStatus }, index) =>
                            <>
                                <Grid item xs={12} spacing={2} container columns={12} justifyContent="center">
                                    <Grid item xs={3} sx={{ minWidth: "200px" }} >
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id={`palisade-id-${index}`}
                                            label="Sensor Id"
                                            fullWidth
                                            variant="outlined"
                                            defaultValue={palisade}
                                            onChange={(event) => { handleChange(event, index, 'palisade') }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3} sx={{ minWidth: "200px" }}>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id={`mcu-serial-${index}`}
                                            label="MCU Serial"
                                            fullWidth
                                            variant="outlined"
                                            defaultValue={serialNo}
                                            onChange={(event) => { handleChange(event, index, 'serialNo') }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3} sx={{ minWidth: "160px" }}>
                                        <FormControl required fullWidth sx={{ mt: 1 }} margin="dense" >
                                            <InputLabel id="hw-version-select-label">HW Version</InputLabel>
                                            <Select
                                                labelId="hw-version-select-label"
                                                id={`hw-version-select-${index}`}
                                                value={hwVersion}
                                                label="HW Version"
                                                onChange={(event) => { handleChange(event, index, 'hwVersion') }}
                                            >
                                                <MenuItem value={0}>{"3.2"}</MenuItem>
                                                <MenuItem value={1}>{"3.3"}</MenuItem>
                                                <MenuItem value={2}>{"3.3a"}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} sx={{ minWidth: "200px" }}>
                                        <FormControl required fullWidth sx={{ mt: 1 }} margin="dense" >
                                            <InputLabel id="mcu-model-select-label">MCU Model No.</InputLabel>
                                            <Select
                                                labelId="mcu-model-select-label"
                                                id={`mcu-model-select-${index}`}
                                                value={modelNo}
                                                label="MCU Model No."
                                                onChange={(event) => { handleChange(event, index, 'modelNo') }}
                                            >
                                                <MenuItem value={0}>{"1.0.0"}</MenuItem>
                                                <MenuItem value={1}>{"2.0.0"}</MenuItem>
                                                <MenuItem value={2}>{"2.1.1"}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} sx={{ minWidth: "200px" }}>
                                        <FormControl required fullWidth sx={{ mt: 1 }} margin="dense">
                                            <InputLabel id="tenant-select-label">Tenant</InputLabel>
                                            <Select
                                                labelId="tenant-select-label"
                                                id={`tenant-select-${index}`}
                                                value={tenant}
                                                label="Tenant"
                                                onChange={(event) => {
                                                    tenantChange(event, index, 'tenant');
                                                }}
                                            >
                                                {
                                                    tenantList.map(({ id, name }) =>
                                                        <MenuItem value={id}>{name}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} sx={{ minWidth: "200px" }}>
                                        <FormControl required fullWidth sx={{ mt: 1 }} margin="dense">
                                            <InputLabel id="aggregator-select-label">Aggregator</InputLabel>
                                            <Select
                                                labelId="aggregator-select-label"
                                                id={`aggregator-select-${index}`}
                                                value={aggregator}
                                                label="Aggregator"
                                                onChange={(event) => { handleChange(event, index, 'aggregator') }}
                                            >
                                                {
                                                    aggregatorMap?.get(tenant)?.map(id =>
                                                        <MenuItem value={id}>{id}</MenuItem>
                                                    )
                                                }
                                                {tenant === inventoryTenantId ? <MenuItem value={noAggregator}>{noAggregator}</MenuItem> : null}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} sx={{ minWidth: "200px" }}>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id={`MACAdress-${index}`}
                                            label="MAC Address"
                                            fullWidth
                                            variant="outlined"
                                            value={macAddress}
                                            onChange={(event) => { handleChange(event, index, 'macAddress') }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3} sx={{ minWidth: "200px" }}>
                                        <FormControl required fullWidth sx={{ mt: 1 }} margin="dense">
                                            <InputLabel id="aggregator-select-label">Chip</InputLabel>
                                            <Select
                                                labelId="chip-select-label"
                                                id={`chip-select-${index}`}
                                                value={chip}
                                                label="Chip"
                                                onChange={(event) => { handleChange(event, index, 'chip') }}
                                            >
                                                <MenuItem value={0}>{"ATSAMV71Q19"}</MenuItem>
                                                <MenuItem value={1}>{"ATSAME70Q20"}</MenuItem>
                                                <MenuItem value={2}>{"ATSAME70Q21"}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={3} sx={{ minWidth: "200px" }}>
                                        <FormControl required fullWidth sx={{ mt: 1 }} margin="dense">
                                            <InputLabel id="canInterface-select-label">CAN Interface</InputLabel>
                                            <Select
                                                labelId="canInterface-select-label"
                                                id={`canInterface-select-${index}`}
                                                value={canInterface}
                                                label="CAN Interface"
                                                onChange={(event) => { handleChange(event, index, 'canInterface') }}
                                            >
                                                <MenuItem value={true}>{"True"}</MenuItem>
                                                <MenuItem value={false}>{"False"}</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} sx={{ minWidth: "220px" }}>
                                        <FormControl required fullWidth sx={{ mt: 1 }} margin="dense">
                                            <InputLabel id="powerConnector-select-label">Power Connector</InputLabel>
                                            <Select
                                                labelId="powerConnector-select-label"
                                                id={`powerConnector-select-${index}`}
                                                value={powerConnector}
                                                label="Power Connector"
                                                onChange={(event) => { handleChange(event, index, 'powerConnector') }}
                                            >
                                                <MenuItem value={0}>{"Lenovo Slim Tip"}</MenuItem>
                                                <MenuItem value={1}>{"Dell 4.5mm DC jack"}</MenuItem>
                                                <MenuItem value={2}>{"T Connector"}</MenuItem>
                                                <MenuItem value={3}>{"Barrel Connector"}</MenuItem>
                                                <MenuItem value={4}>{"None"}</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} sx={{ minWidth: "210px" }}>
                                        <DeploymentStatusDropdown
                                            index={index}
                                            handleChange={(event) => { handleChange(event, index, 'deploymentStatus') }}
                                            deploymentStatus={deploymentStatus}
                                        />
                                    </Grid>
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={12} >
                                        <Divider sx={{ backgroundColor: '#5B5F66' }} />
                                    </Grid>

                                </Grid>
                                <Grid item xs={1} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {
                                        index + 1 === data.length ?
                                            <IconButton onClick={handleExtraAdd}>
                                                <AddCircleOutline />
                                            </IconButton>
                                            :
                                            <></>
                                    }
                                </Grid>
                            </>
                        )
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={addPalisades} sx={{ marginRight: "15px", marginBottom: "15px" }} variant="contained">Add</Button>
            </DialogActions>
        </Dialog >
    );
}

export default AddPalisadeDialog