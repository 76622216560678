import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import InventoryTable from './InventoryTable';
import AddTenantsDialog from '../Dialog/AddTenantsDialog';
import { useMsal, useAccount } from "@azure/msal-react";
import DeleteTenant from '../../API/DeleteTenant';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from '../Dialog/ConfirmationDialog';

const baseColumns = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Tenant Id',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Tenant Name',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description',
    },
];

const baseActions = new Map([
    [
        'delete',
        {
            icon: "delete",
            tooltip: "Delete",
            color: "error",
            onClick: () => { },
        }
    ]
]);

const Tenants = (props) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [rows, setRows] = useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [deleteTenantId, setDeleteTenantId] = React.useState();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
    const [actions, setActions] = useState(baseActions);

    useEffect(() => {
        const role = account.idTokenClaims?.extension_appRole?.toLowerCase();
        if (account) {
            setRows(props.tenants.map(
                ({ id, name, description }) =>
                    ({ id, name, description })
            ));
        }
        if (role !== "superadmin") {
            actions.delete('delete');
            setActions(actions);
        }
    }, [account, instance, props.tenants]);

    const addRow = ({ id, name, description }) => {
        setRows(rows => [...rows, { id, name, description }]);
    }

    if (actions.get('delete')) {
        actions.get('delete').onClick = (event, tenantId) => {
            setDeleteTenantId(tenantId);
            setDeleteConfirmOpen(true);
        }
    }

    const deleteTenant = () => {
        DeleteTenant(instance, account, deleteTenantId)
            .then((response) => {
                if (response.ok) {
                    toast.success("Tenant deleted successfully");
                } else {
                    toast.error("Cannot delete tenant with assigned devices");
                }
            },
                (error) => { toast.error("Cannot delete invalid tenant") });
    }

    return (
        <>
            <InventoryTable
                rows={rows}
                columns={baseColumns}
                selected={selectedRows}
                actions={actions}
                setSelected={setSelectedRows}
                checkbox={false}
            />
            <AddTenantsDialog
                open={props.openAddDialog}
                handleClose={props.handleAddClose}
                addRow={addRow}
            />
            <ConfirmationDialog
                title="DELETE TENANT"
                open={deleteConfirmOpen}
                setOpen={setDeleteConfirmOpen}
                onConfirm={deleteTenant}
            >
                Are you sure you want to delete tenant {deleteTenantId} and all associated targets?
            </ConfirmationDialog>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        tenants: state.tenants
    }
}

export default connect(mapStateToProps)(Tenants);