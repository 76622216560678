const numMillisecondsInDay = 1000 * 60 * 60 * 24;
const numMillisecondsInHour = 1000 * 60 * 60;
const numMillisecondsInMin = 1000 * 60;
const numMillisecondsInSecond = 1000;

// expects time string of format "MM/DD/YYYY HH:MM:SS"
export function getDifferenceInTime(time) {
  let timeStr = "";
  if (time != null) {

    let parts = time.split("/");
    let month = parseInt(parts[0]);
    let day = parseInt(parts[1]);
    let rest = parts[2].split(" ");
    let year = parseInt(rest[0]);
    let detailedTimes = rest[1].split(":");
    let hour = parseInt(detailedTimes[0]);
    let min = parseInt(detailedTimes[1]);
    let seconds = parseInt(detailedTimes[2]);

    // last upload is in UTC
    let dt = new Date(Date.now());
    let targetTime = new Date(Date.UTC(year, month - 1, day, hour, min, seconds));
    let diffTime = Math.abs(dt - targetTime);

    let diffDays = Math.floor(diffTime / numMillisecondsInDay);
    let diffHours = Math.floor(diffTime / numMillisecondsInHour);
    let diffMins = Math.floor(diffTime / numMillisecondsInMin);
    let diffSeconds = Math.floor(diffTime / numMillisecondsInSecond);

    if (diffDays > 0) {
      diffDays == 1 ? (timeStr = `${diffDays} day ago`) : (timeStr = `${diffDays} days ago`);
    }
    else if (diffHours > 0) {
      diffHours == 1 ? (timeStr = `${diffHours} hour ago`) : (timeStr = `${diffHours} hours ago`)
    }
    else if (diffMins > 0) {
      diffMins == 1 ? (timeStr = `${diffMins} minute ago`) : (timeStr = `${diffMins} minutes ago`)
    }
    else if (diffSeconds > 0) {
      diffSeconds == 1 ? (timeStr = `${diffSeconds} second ago`) : (timeStr = `${diffSeconds} seconds ago`)
    }
    else {
      timeStr = "now";
    }
  }
  return timeStr;
}

export function milliSecondsToTime(milliseconds) {
  const minutes = `${Math.floor((milliseconds % numMillisecondsInHour) / numMillisecondsInMin)}`.padStart(2, '0');
  const hours = `${Math.floor(milliseconds / numMillisecondsInHour)}`.padStart(2, '0');
  return `${hours}:${minutes}`;
}

export function timeToMilliSeconds(time) {
  const hours = parseInt(time.substr(0, 2));
  const minutes = parseInt(time.substr(3, 2));
  return hours * numMillisecondsInHour + minutes * numMillisecondsInMin;
}

export default getDifferenceInTime;