import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const PalitronicaTheme = createTheme({
  palette: {
    primary: {
      main: grey[900],
    },
  },
});

export default PalitronicaTheme