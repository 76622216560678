import AcquireToken from "./AcquireToken";
import { AGGREGATOR_STATS_API } from "./Endpoints";
import { setAggregatorStats } from "../reducer/aggregators";

async function GetAggregatorStats(instance, account) {
  const accessToken = await AcquireToken(instance, account);
  if (accessToken) {
    return (
      await fetch(AGGREGATOR_STATS_API, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).json();
  } else {
    return [];
  }
}

export function fetchAggregatorStats(instance, account) {
  return async (dispatch, getState) => {
    const response = await GetAggregatorStats(instance, account);
    dispatch(setAggregatorStats(response));
  };
}

export default fetchAggregatorStats;
