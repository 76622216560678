import React from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import { FlashOn } from "@mui/icons-material";
import CANIcon from "../Icons/CANIcon";
import IconButton from "@mui/material/IconButton";
import Aggregators from "../Tables/AggregatorSensors";
import PalisadeSensors from "../Tables/PalisadeSensors";
import { Card, Select } from "@mui/material";
import { useMsal, useAccount } from "@azure/msal-react";
import { ToastContainer } from "react-toastify";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import GetTenants from "../../API/GetTenants";
import { inventoryTenantId } from "../Utilities/api";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SensorsPage = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [value, setValue] = React.useState(0);  // Selected Tab
  const [displayTransmissionEnable, setDisplayTransmissionEnable] = React.useState("none");
  const [enableSelectedTransmission, setEnableSelectedTransmission] = React.useState(false);
  const [enableSelectedCANTransmission, setEnableSelectedCANTransmission] = React.useState(false);
  const [anySelected, setAnySelected] = React.useState(false);
  const [superAccount, setSuperAccount] = React.useState(false);
  const [tenantList, setTenantList] = React.useState([]);
  const [tenant, setTenant] = React.useState('');

  const handleEnableSelectedTransmission = () => {
    setEnableSelectedTransmission(true);
  }

  const finishEnableSelectedTransmission = () => {
    setEnableSelectedTransmission(false);
  }

  const handleEnableSelectedCANTransmission = () => {
    setEnableSelectedCANTransmission(true);
  }

  const finishEnableSelectedCANTransmission = () => {
    setEnableSelectedCANTransmission(false);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTenantChange = (event) => {
    setTenant(event.target.value);
    setAnySelected(false);
  }

  React.useEffect(() => {
    async function getData() {
      if (account) {
        const tenants = await GetTenants(instance, account)
        setTenantList(tenants);
        setTenant(account.idTokenClaims?.extension_appTenantId || tenants[0]?.id || '');
      }
    }
    getData();
    if (account) {
      const role = account.idTokenClaims?.extension_appRole?.toLowerCase();
      if (role === "superadmin") {
        setDisplayTransmissionEnable("flex");
        setSuperAccount(true);
      } else if (role === "admin") {
        setDisplayTransmissionEnable("flex");
        setSuperAccount(false);
      } else if (role === "superuser") {
        setSuperAccount(true);
      } else if (role === "user") {
        setSuperAccount(false);
      }
    }
  }, [account, instance]);

  return (
    <Box sx={{ padding: '20px', paddingBottom: '0' }}>
      <Card>
        <Grid container sx={{ height: '75px', paddingBottom: '5px' }} alignItems="flex-end">
          <Grid item xs={6} sx={{ paddingBottom: '5px' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Palisade Sensors" {...a11yProps(0)} />
              <Tab label="Aggregators" {...a11yProps(1)} />
            </Tabs>
          </Grid>
          <Grid item xs={6} sx={{ display: `flex`, alignItems: "flex-end", justifyContent: "flex-end", paddingBottom: '5px' }}>
            {
              superAccount &&
              value === 0 &&
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="tenant-select" sx={{}}>Tenant</InputLabel>
                <Select
                  labelId="tenant-select"
                  id="tenant-select"
                  label="Tenant"
                  onChange={handleTenantChange}
                  value={tenant}
                >
                  {
                    tenantList.map(({ id, name }) =>
                      id === inventoryTenantId ?
                        <></> :
                        <MenuItem value={id}>{name}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            }
            <IconButton onClick={handleEnableSelectedTransmission} disabled={!anySelected} sx={
              { display: value === 0 ? `${displayTransmissionEnable}` : "none" }
            }>
              <FlashOn />
            </IconButton>
            <IconButton onClick={handleEnableSelectedCANTransmission} disabled={!anySelected} sx={
              { display: value === 0 ? `${displayTransmissionEnable}` : "none" }
            }>
              <CANIcon disabled={!anySelected} />
            </IconButton>
          </Grid>
        </Grid>
        <TabPanel value={value} index={0}>
          <PalisadeSensors
            enableSelectedTransmission={enableSelectedTransmission}
            finishEnableSelectedTransmission={finishEnableSelectedTransmission}
            enableSelectedCANTransmission={enableSelectedCANTransmission}
            finishEnableSelectedCANTransmission={finishEnableSelectedCANTransmission}
            setAnySelected={setAnySelected}
            tenant={tenant}
            superAccount={superAccount}
            anySelected={anySelected}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Aggregators
            setAnySelected={setAnySelected}
          />
        </TabPanel>
      </Card>
      <ToastContainer />
    </Box>
  );
}
export default SensorsPage;