import AcquireToken from "./AcquireToken";
import { SEND_INVITATION_API } from "./Endpoints";

async function PostInvite(instance, account, invite) {
    const accessToken = await AcquireToken(instance, account);
    if (accessToken) {
        return (await fetch(
            SEND_INVITATION_API,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(invite),
            }
        ))
    } else {
        return null;
    }
}

export default PostInvite