import AcquireToken from "./AcquireToken";
import { TARGET_API } from "./Endpoints";

async function DeleteTarget(instance, account, tenantId, targetId) {
    const accessToken = await AcquireToken(instance, account);
    if (accessToken) {
        return (await fetch(
            `${TARGET_API}?tenantId=${tenantId}&targetId=${targetId}`,
            {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            }
        ))
    } else {
        return null;
    }
}

export default DeleteTarget