import AcquireToken from "./AcquireToken";
import { ENERGY_API } from "./Endpoints";
import {
  setEnergyData,
  setEnergyChangeOverPastWeek,
  setEnergyUsageChartData,
} from "../reducer/energy";

async function GetEnergyData(instance, account, weeks = 10) {
  const accessToken = await AcquireToken(instance, account);
  if (accessToken) {
    return await fetch(ENERGY_API + `?weeks=${weeks}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } else {
    return [];
  }
}

export function fetchEnergyData(instance, account) {
  return async (dispatch, getState) => {
    const response = await GetEnergyData(instance, account);
    try {
      const energyResponseJson = await response.json();
      energyResponseJson.sort((a, b) => (a.week > b.week ? 1 : -1));
      const energyConsumptionData = energyResponseJson.map(
        (obj) => obj.energyConsumption
      );

      dispatch(setEnergyData(energyConsumptionData));
      dispatch(
        setEnergyChangeOverPastWeek(
          energyConsumptionData
            ? parseInt(
                ((energyConsumptionData.at(-1) - energyConsumptionData.at(-2)) /
                  energyConsumptionData.at(-2)) *
                  100
              )
            : 0
        )
      );
      if (energyResponseJson.length > 0) {
        let energyUsageChartValues = {
          labels: energyResponseJson.map((obj) => obj.week),
          datasets: [
            {
              data: energyResponseJson.map((obj) => obj.energyConsumption),
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgb(53, 162, 235)",
              label: false,
            },
          ],
        };
        energyUsageChartValues.labels[0] = [
          energyUsageChartValues.labels[0],
          "wk",
        ];
        dispatch(setEnergyUsageChartData(energyUsageChartValues));
      }
    } catch (err) {
      console.log("Unable to fetch Energy data");
    }
  };
}

export default fetchEnergyData;
