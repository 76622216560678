import React, { useState, useEffect } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { FlashOn, FlashOff } from '@mui/icons-material';
import CANIcon from '../Icons/CANIcon';

const TransmissionCell = (props) => {
    const transmissionEnabled = props.transmissionEnabled

    const handleTransmission = (newTransmission) => {
        if (newTransmission !== null && !props.readonly) {
            props.handleTransmission(newTransmission);
        }
    };
    
    var tooltipTitle = props.can ?
        (transmissionEnabled ? "CAN Transmission Enabled" : "CAN Transmission Disabled") :
        (transmissionEnabled ? "Transmission Enabled" : "Transmission Disabled");
    var icon = props.can ? 
        (<CANIcon transmissionEnabled={transmissionEnabled}/>) :
        (transmissionEnabled ? <FlashOn color="success"/> : <FlashOff/>);

    return (
        <Tooltip title={tooltipTitle}>
            <IconButton onClick={() => handleTransmission(!transmissionEnabled)}>
                {icon}
            </IconButton>
        </Tooltip>
    )
}

export default TransmissionCell