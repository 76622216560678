import { Paper } from '@mui/material';
import { grey } from '@mui/material/colors';

const CANIcon = (props) =>
        <Paper sx={{
            fontSize: 12,
            fontWeight: "bold",
            padding: 0.5,
            bgcolor: props.transmissionEnabled ? "success.main" : (props.disabled ? grey[400] : "gray"),
            color: "white"
        }}>
            CAN
        </Paper>

export default CANIcon;