import {
    Grid,
    Switch,
    Box,
    TextField,
    InputAdornment
} from "@mui/material";
import React, { useState } from 'react';

const SCRADRule = (props) => {
    const [invalidConfidence, setInvalidConfidence] = useState(false);

    const isInvalidConfidence = (confidence) => {
        return (isNaN(confidence) || confidence < 10 || confidence > 100 || (confidence % 1) !== 0);
    }

    const handleConfidenceChange = (event) => {
        var newRule = props.rule;
        var parameters = JSON.parse(props.rule.parameters);
        parameters["confidence-threshold"] = event.target.value / 100;
        newRule.parameters = JSON.stringify(parameters);
        props.addChangedRule(newRule);
        const newConfidenceInvalid = isInvalidConfidence(Math.round(JSON.parse(props.rule.parameters)["confidence-threshold"] * 100));
        if (newConfidenceInvalid !== invalidConfidence) {
            props.changeNumInputErrors(newConfidenceInvalid);
            setInvalidConfidence(newConfidenceInvalid);
        }
    }

    return (
        <Grid container>
            <Grid item xs={11} sx={{ display: `flex`, alignItems: "center", justifyContent: "flex-start" }}>
                <Box sx={{ display: `flex`, alignItems: "center", fontSize: 20, padding: 2 }}>
                    <b>When&nbsp;</b> a spreading ransomware attack is detected with a confidence of&nbsp;
                    <TextField
                        disabled={!props.editsAuthorized}
                        value={Math.round(JSON.parse(props.rule.parameters)["confidence-threshold"] * 100)}
                        onChange={handleConfidenceChange}
                        label="Confidence"
                        size="small"
                        type="number"
                        fullWidth
                        InputProps={{
                            inputProps: { max: 100, min: 10 },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        sx={{
                            width: 120,
                        }}
                        error={isInvalidConfidence(Math.round(JSON.parse(props.rule.parameters)["confidence-threshold"] * 100))}

                    />
                    ,<b>&nbsp;Then&nbsp;</b> send an alert
                </Box>
            </Grid>
            <Grid item xs={1} sx={{ display: `flex`, alignItems: "center", justifyContent: "flex-end" }}>
                <Switch
                    checked={props.rule.enabled}
                    color={"success"}
                    disabled={!props.editsAuthorized}
                    onChange={(event) => { props.handleEnabledChange(props.rule) }} />
            </Grid>
        </Grid>
    )
};

export default SCRADRule;