import React, { useState, useEffect } from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import GetPalisadeInventorySummary from '../../API/GetPalisadeInventorySummary';
import InventoryTable from './InventoryTable';

const baseColumns = [
    {
        id: 'chipVersion',
        numeric: false,
        disablePadding: false,
        label: '',
    },
    {
        id: 'inStock',
        numeric: true,
        disablePadding: false,
        label: 'In Stock',
    },
    {
        id: 'deployment',
        numeric: true,
        disablePadding: false,
        label: 'Deployment',
    },
    {
        id: 'unknown',
        numeric: true,
        disablePadding: false,
        label: 'Unknown',
    },
    {
        id: 'personalUse',
        numeric: true,
        disablePadding: false,
        label: 'Testing',
    },
    {
        id: 'total',
        numeric: true,
        disablePadding: false,
        label: 'Total',
    },
];

const InventorySummary = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [ rows, setRows ] = useState([]);

    useEffect(() => {
        async function getData () {
            const role = account.idTokenClaims?.extension_appRole?.toLowerCase();
            if (account) {
                const summary = await GetPalisadeInventorySummary(instance, account);
                setRows(Object.entries(summary).map(
                    ([chipVersion, {inStock, deployment, unknown, personalUse, total}]) => ({id: chipVersion, chipVersion, inStock, deployment, unknown, personalUse, total})
                ))
            }
            if (role !== "superadmin" && role !== "admin") {
            }
        }
        getData();
    }, [account, instance]);
    
    return (<InventoryTable 
        rows={rows}
        columns={baseColumns}
        selected={[]}
        actions={new Map([])}
        setSelected={() => {}}
        checkbox={false}
        pagination={false}
        />
    );
}

export default InventorySummary;