const NotificationTypes = [
    "UserInvitation",
    "RuleAlert",
    "PalisadeAdded",
    "PalisadeDeleted",
    "PalisadeTransmissionEnabled",
    "PalisadeTransmissionDisabled",
    "PalisadeReset",
    "AggregatorAdded",
    "AggregatorDeleted",
    "TargetAdded",
    "TargetDeleted",
    "RuleAdded",
    "RuleDeleted",
    "RuleEnabled",
    "RuleDisabled",
    "UserDeleted",
    "TenantAdded",
    "TenantDeleted",
    "NUM_NOTIFICATIONS"
];

export default NotificationTypes;