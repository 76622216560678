import AcquireToken from "./AcquireToken";
import { PALISADE_API } from "./Endpoints";

async function DeletePalisade(instance, account, id) {
    const accessToken = await AcquireToken(instance, account);
    if (accessToken) {
        return (await fetch(
            `${PALISADE_API}?id=${id}`,
            {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            }
        ))
    } else {
        return null;
    }
}

export default DeletePalisade