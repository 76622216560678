import { useEffect, useState } from "react";
import { useParams } from "react-router";
import PostRedemption from "../../API/PostRedeemInvite";

const Invitation = () => {
    const params = useParams();
    const jwt = params.jwt;

    const [displayText, setDisplayText] = useState("Redeeming invite...")

    useEffect(() => {
        async function getRedirect() {
            const response = await PostRedemption({hint: jwt});
            if (response.ok) {
                const redirect = await response.json();
                window.location.replace(redirect.url);  // https://developer.mozilla.org/en-US/docs/Web/API/Location/replace
            }
            else {
                setDisplayText("Invalid invite");
            }
        }
        getRedirect();
    });

    return (
        <p>{displayText}</p>
    );
}

export default Invitation;