import AcquireToken from "./AcquireToken";
import { AGGREGATOR_API } from "./Endpoints";

async function GetAggregators(instance, account) {
    const accessToken = await AcquireToken(instance, account);
    if (accessToken) {
        return (await fetch(
            AGGREGATOR_API,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
            }
        )).json()
    } else {
        return [];
    }
}

export default GetAggregators