import AcquireToken from "./AcquireToken";
import { NOTIFICATION_SIGNALR_HUB } from "./Endpoints";
import { toast } from "react-toastify";
import NotificationTypes from "../Models/NotificationTypes";

async function InitSignalR(instance, account) {
    const signalR = require("@microsoft/signalr");

    const connection = new signalR.HubConnectionBuilder()
        .withUrl(NOTIFICATION_SIGNALR_HUB, { accessTokenFactory: async () => await AcquireToken(instance, account) })
        .configureLogging(signalR.LogLevel.Information)
        .build();

    async function start() {
        try {
            await connection.start();
        } catch (err) {
            setTimeout(start, 5000);
        }
    };

    connection.on("ReceiveNotification", (notification) => {
        switch (NotificationTypes[notification.type]) {
            case "PalisadeReset":
                toast.dismiss(notification.notifierId);
                toast.success(`Palisade ${notification.notifierId} successfully reset`);
                break;
            case "UserInvitation":
                toast.dismiss(notification.recipient);
                if (notification.success) {
                    toast.success(`Invite successfully sent to ${notification.recipient}`);
                } else {
                    toast.error("Unable to send invite");
                }
                break;
            default:
                toast.error("Unrecognized notification");
        }
    });
    
    connection.onclose(async () => {
        await start();
    });

    // Start the connection.
    start();
}

export default InitSignalR